import "./Navigation.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  NavbarToggler,
  NavbarText,
  Container,
} from "reactstrap";
import twitterIcon from "../img/twitter.svg";
import discordIcon from "../img/discord.svg";
import icon128 from "../img/icon128.png";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [activeNav, setActiveNav] = useState(false);
  const updateActiveNav = () => {
    if (isOpen) toggle();
    setActiveNav(!activeNav);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [activeNav]);

  const HomePage = "/";
  const BuyPage = "/buy";
  const HelpPage = "/faq";
  const ExplorePage = "/explore";
  const PoliciesPage = "/policies";

  const NavClassCurrentPage = "Nav-current-page";
  const NavClassOtherPage = "Nav-other-page";

  const [currentPage, setCurrentPage] = useState("/");

  const getNavClass = (navPageName) => {
    if (navPageName === HomePage) {
      return currentPage === navPageName
        ? NavClassCurrentPage
        : NavClassOtherPage;
    }
    return currentPage.startsWith(navPageName)
      ? NavClassCurrentPage
      : NavClassOtherPage;
  };

  return (
    <Container className="Navigation">
      <Navbar className="Navbar" dark expand="lg">
        <NavbarBrand
          className="Nav-brand"
          tag={Link}
          to="/"
          onClick={updateActiveNav}
        >
          <img src={icon128} className="Nav-icon" alt="Adaz CNFT" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to={HomePage} onClick={updateActiveNav}>
                <span className={getNavClass(HomePage)}>Home</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={BuyPage} onClick={updateActiveNav}>
                <span className={getNavClass(BuyPage)}>Get Adaz</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={ExplorePage} onClick={updateActiveNav}>
                <span className={getNavClass(ExplorePage)}>Explore NFTs</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={HelpPage} onClick={updateActiveNav}>
                <span className={getNavClass(HelpPage)}>FAQ</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to={PoliciesPage} onClick={updateActiveNav}>
                <span className={getNavClass(PoliciesPage)}>Policy IDs</span>
              </NavLink>
            </NavItem>
          </Nav>
          <NavbarText>
            <a
              href="https://twitter.com/AdazNFT"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterIcon} alt="Twitter" className="Link-icon" />
            </a>
            <a
              href="https://discord.gg/DBQJzfdASG"
              target="_blank"
              rel="noreferrer"
            >
              <img src={discordIcon} alt="Discord" className="Link-icon" />
            </a>
          </NavbarText>
        </Collapse>
      </Navbar>
    </Container>
  );
};

export default Navigation;
