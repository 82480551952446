import "./Content.css";
import "./Explore.css";
import { Container, Row, Col, NavLink } from "reactstrap";
import SocialIcons from "./SocialIcons";
import { Projects } from "../services/Constants";
import { useHistory } from "react-router-dom";
import ExploreAdaz from "./ExploreAdaz";
import ExploreNightmares from "./ExploreNightmares";
import ExploreBloodlines from "./ExploreBloodlines";

const Explore = (props) => {
  const history = useHistory();
  const navigate = (path) => {
    history.push(path);
  };
  const isAdaz = props.project === Projects.adaz || !props.project;
  const isBloodlines = props.project === Projects.bloodlines;
  const isNightmares = props.project === Projects.nightmares;
  return (
    <Container>
      <Row className="Content-space-side center-text">
        <Col xs="12" className="center-text">
          <h1 className="Content-h1">Explore NFTs</h1>
        </Col>
        <Col md="4">
          <NavLink
            className={`Explore-project-link${isAdaz ? "-current" : ""}`}
            onClick={() => navigate("/explore/adaz")}
          >
            Adaz
          </NavLink>
        </Col>
        <Col md="4">
          <NavLink
            className={`Explore-project-link${isBloodlines ? "-current" : ""}`}
            onClick={() => navigate("/explore/bloodlines")}
          >
            Bloodlines
          </NavLink>
        </Col>
        <Col md="4">
          <NavLink
            className={`Explore-project-link${isNightmares ? "-current" : ""}`}
            onClick={() => navigate("/explore/nightmares")}
          >
            Nightmares
          </NavLink>
        </Col>
      </Row>
      <p></p>
      {isAdaz && <ExploreAdaz />}
      {isBloodlines && <ExploreBloodlines />}
      {isNightmares && <ExploreNightmares />}

      <Row className="Content-space-side">
        <Col className="center-text">
          <SocialIcons />
        </Col>
      </Row>
    </Container>
  );
};

export default Explore;
