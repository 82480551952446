import "./Content.css";
import { Container, Row, Col } from "reactstrap";
import SocialIcons from "./SocialIcons";
import { MarketPlaceButton } from "./BuyPage/MarketplaceButton";
import cnftImage from "../img/marketplaces/marketplace_cnftio.png";
import jpgstoreImage from "../img/marketplaces/marketplace_jpgstore.png";
import tokhunImage from "../img/marketplaces/marketplace_tokhun.png";
import genesisImage from "../img/marketplaces/marketplace_genesishouse.png";
import headerAdazImage from "../img/marketplaces/header_adaz.jpg";
import headerBloodlinesImage from "../img/marketplaces/header_bloodlines.jpg";
import headerNightmaresImage from "../img/marketplaces/header_nightmares.jpg";

const Marketplaces = () => {
  return (
    <Container>
      <Row>
        <Col className="center-text">
          <h1 className="Content-h1">Buy Adaz NFTs</h1>
          <p className="Content-text">
            The initial mint event is over. You can find the Adaz NFTs on
            different Cardano marketplaces.
          </p>
          <p className="Content-text">
            Or you can try to get in touch with Adaz NFT owners through{" "}
            <a href="https://discord.gg/DBQJzfdASG" alt="discord invite">
              Discord
            </a>
            .
          </p>
          <h2 className="Content-h2">Adaz</h2>
          <Row className="Content-space-side">
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={jpgstoreImage}
                link="https://www.jpg.store/collection/adaz"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={cnftImage}
                link="https://cnft.io/project/adaz"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={tokhunImage}
                link="https://tokhun.io/marketplace?project%5B%5D=701"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={genesisImage}
                link="https://www.genesishouse.io/collections/464"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                className="Content-text Content-full-width-image Content-spacer"
                src={headerAdazImage}
                alt="Adaz NFT preview"
              />
            </Col>
          </Row>
          <h2 className="Content-h2 Content-spacer">Adaz Bloodlines</h2>
          <Row className="Content-space-side">
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={jpgstoreImage}
                link="https://www.jpg.store/collection/adazbloodlines"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={cnftImage}
                link="https://cnft.io/project/adaz-bloodlines"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={tokhunImage}
                link="https://tokhun.io/marketplace?project%5B%5D=701"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={genesisImage}
                link="https://www.genesishouse.io/collections/3675"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                className="Content-text Content-full-width-image Content-spacer"
                src={headerBloodlinesImage}
                alt="Adaz Bloodlines NFT preview"
              />
            </Col>
          </Row>
          <h2 className="Content-h2 Content-spacer">Adaz Nightmares</h2>
          <Row className="Content-space-side">
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={jpgstoreImage}
                link="https://cnft.io/project/adaz-nightmares"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={cnftImage}
                link="https://cnft.io/marketplace?verified=true&project=Adaz%20Nightmares"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={tokhunImage}
                link="https://tokhun.io/marketplace?project%5B%5D=702"
              />
            </Col>
            <Col className="col-md-3 col-6">
              <MarketPlaceButton
                image={genesisImage}
                link="https://www.genesishouse.io/collections/751"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                className="Content-text Content-full-width-image Content-spacer"
                src={headerNightmaresImage}
                alt="Adaz Nightmares NFT preview"
              />
            </Col>
          </Row>
          <SocialIcons></SocialIcons>
        </Col>
      </Row>
    </Container>
  );
};

export default Marketplaces;
