import "./MarketplaceButton.css";

export const MarketPlaceButton = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <div className="MarketplaceButton">
        <img src={props.image} alt="marketplace button" />
      </div>
    </a>
  );
};
