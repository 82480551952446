import "./NFT.css";

export const NFT = (props) => {
  return (
    <div className="nft">
      {props.url ? (
        <>
          <a href={props.url} target="_blank" rel="noreferrer">
            <img src={props.image} alt="NFT" />
          </a>
          {props.sub && <p>{props.sub}</p>}
        </>
      ) : (
        <img src={props.image} alt="NFT" />
      )}
      <p>
        <b>{props.name}</b>
      </p>
      {props.bloodline && <p>Bloodline: {props.bloodline}</p>}
      {props.gen && <p>Generation: {props.gen}</p>}
      {props.border && <p>Border: {props.border}</p>}
    </div>
  );
};
