import "./Content.css";
import { Container, Row, Col } from "reactstrap";
import SocialIcons from "./SocialIcons";

const Policies = () => {
  return (
    <Container>
      <Row>
        <Col className="center-text">
          <h1 className="Content-h1">NFT Policy IDs</h1>
          <p className="Content-text Content-space-side">
            Each Cardano NFT has a policy ID. Below you can find the official
            Policy IDs of our projects. If you see an NFT that looks like one of
            our NFTs but has a different policy IDs, it's a fake! Make sure to
            always check the policy ID!
          </p>
          <h2 className="Content-h2">Project: Adaz</h2>
          <p className="Content-small-margin-bottom">
            Adaz NFT policy, locks 01. January 2022
          </p>
          <p className="Content-text">
            <code className="">
              6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd
            </code>
          </p>
          <p className="Content-small-margin-bottom">
            Adaz animated bloodline NFT policy, locks 31. October 2022
          </p>
          <p className="Content-text">
            <code>
              7a245d37eef9081474eed91f351c9149d3014f451681b43a8474031f
            </code>
          </p>
          <p>
            CNFT verification: <code>VERIFIED✅</code>
          </p>
          <h2 className="Content-h2">Project: Adaz Nightmares</h2>
          <p className="Content-small-margin-bottom">Locks 30. November 2021</p>
          <p className="Content-text Content-small-margin-bottom">
            <code>
              fbfa072b4493c0a17ac42ce9d9146804b342be4f898e269950182240
            </code>
          </p>
          <p>
            CNFT verification: <code>VERIFIED✅</code>
          </p>
          <SocialIcons></SocialIcons>
        </Col>
      </Row>
    </Container>
  );
};

export default Policies;
