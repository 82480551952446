import "./Content.css";
import { Container, Row, Col } from "reactstrap";
import QuestionAnswer from "./QuestionAnswer";
import SocialIcons from "./SocialIcons";
import Twitter from "./Text/Twitter";
import Discord from "./Text/Discord";
import sistersImage from "../img/sisters.png";
import bloodlinesImage from "../img/bloodlines.png";

const FAQ = () => {
  return (
    <Container fluid>
      <Row>
        <Col className="center-text">
          <h1 className="Content-h1">FAQ</h1>
          <p className="Content-text">
            We hope that all your questions can be answered here. If not, feel
            free to reach out to us on <Twitter /> or <Discord />.
          </p>

          <QuestionAnswer
            question="When was the project launch?"
            answer="October 3rd 2021, 5:00 PM UTC!"
          ></QuestionAnswer>
          <QuestionAnswer
            question="Where can I buy Adaz NFTs?"
            answer="On any Cardano NFT marketplace or directly from the owners. Some direct links can be found on the 'buy' page."
          ></QuestionAnswer>
          <QuestionAnswer
            question="Is there a place where Adaz collectors can communicate?"
            answer="Please join our Discord server where you can discuss swaps, get some answers or contribute to the project."
          ></QuestionAnswer>
          <QuestionAnswer
            question="What determines the price of Adaz NFTs?"
            answer="Adaz NFTs are traded on open market, so the price is determined by supply and demand. The owner of an Adaz NFT is free to set any price they want."
          ></QuestionAnswer>
          <QuestionAnswer
            question="How many Adaz are there?"
            answer="There are exactly 916 Adaz. 400 'Sisters of Ada Lovelace' and 500 descendants from 100 unique bloodlines were sold. There was the possibility to create 1 new animated NFT for each bloodline. Those NFTs could only be created when someone managed to collect all 5 Adaz from a bloodline before Oct. 31. 2022."
          ></QuestionAnswer>
          <QuestionAnswer
            question="Can I still complete a bloodline?"
            answer="No. 16 bloodlines were completed. The policy is locked since Oct. 31. 2022 and therefore no new Adaz Bloodline NFTs can ever be created again."
          ></QuestionAnswer>
          <QuestionAnswer
            question="What are Sisters of Ada Lovelace?"
            answer="The 'Sisters of Ada' are 400 unique Adaz NFTs. They are simple collectible NFTs and can be recognized by their iron, bronze, silver or gold border. 160/400 iron, 120/400 bronze, 80/400 silver, 40/400 gold."
            image={sistersImage}
          ></QuestionAnswer>
          <QuestionAnswer
            question="What are bloodlines?"
            answer="There are 500 Adaz NFTs that belong to different bloodlines. Each bloodline consists of 5 Adaz. If someone managed to get all 5 Adaz from one bloodline before Oct. 31. 2022, this person was eligible to create a special animated Adaz NFT of this bloodline."
            image={bloodlinesImage}
          ></QuestionAnswer>
          <QuestionAnswer
            question="What are Adaz Nightmares?"
            answer="Adaz Nightmares are 100 NFTs that were created before the launch of the Adaz project. They were used to test the system and were only available for the first 120 people in the Discord server. Adaz Nightmares is a separate project and has a different policy ID than the Adaz project."
          ></QuestionAnswer>

          <SocialIcons></SocialIcons>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
