import "./Content.css";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import { NFTData } from "../services/NFTData";
import Bloodline from "./Bloodline";

const ExploreBloodlines = () => {
  const [count, setCount] = useState(10);
  const [bloodlines, setBloodlines] = useState([]);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showDiscoverable, setShowDiscoverable] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(
      () => (count === 10 ? filter(query.toLowerCase()) : setCount(10)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    filter(query);
  }, [count, showCompleted, showDiscoverable]);

  const filter = async (text) => {
    let tempBloodlines = [];
    for (let i = 0; i < 100; i++) {
      let bloodline = {};
      const bloodlineNfts = NFTData.slice(2 + 5 * i);
      bloodline.gen1 = bloodlineNfts[0];
      bloodline.gen2 = bloodlineNfts[1];
      bloodline.gen3 = bloodlineNfts[2];
      bloodline.gen4 = bloodlineNfts[3];
      bloodline.gen5 = bloodlineNfts[4];
      if (
        (bloodline.gen1.completed === "TRUE" && showCompleted) ||
        (bloodline.gen1.completed === "FALSE" && showDiscoverable)
      ) {
        if (bloodline.gen1.bloodline.toLowerCase().includes(text))
          tempBloodlines.push(bloodline);
      }
    }
    setBloodlines(tempBloodlines.slice(0, count));
  };

  const onFilterChanged = (e, what) => {
    if (what === "completed") {
      setShowCompleted(!showCompleted);
    } else {
      setShowDiscoverable(!showDiscoverable);
    }
    setCount(10);
  };

  return (
    <Row>
      <Col className="center-text">
        <p className="Content-text">
          Check which bloodlines have been completed. (No new bloodlines can be
          completed)
        </p>
        <Row className="center-text Content-space-side">
          <Col md="3" />
          <Col md="6">
            <InputGroup>
              <Input
                placeholder="search for name"
                onChange={(event) => setQuery(event.target.value.toLowerCase())}
              />
            </InputGroup>
          </Col>
          <Col md="3" />
          <Col xs="12">
            <p></p>
          </Col>
          <Col className="center-text">
            <Form>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={showCompleted}
                  onChange={(e) => onFilterChanged(e, "completed")}
                />
                <Label check onClick={(e) => onFilterChanged(e, "completed")}>
                  Show completed
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  type="checkbox"
                  checked={showDiscoverable}
                  onChange={(e) => onFilterChanged(e, "discoverable")}
                />
                <Label
                  check
                  onClick={(e) => onFilterChanged(e, "discoverable")}
                >
                  Show incomplete
                </Label>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <p></p>
        <Row className="center-text Content-space-side">
          {bloodlines.map((bl, i) => {
            return <Bloodline key={`bl_${i}`} nfts={bl} />;
          })}
        </Row>
        {count <= bloodlines.length && count <= 99 && (
          <Row className="Content-space-side">
            <Col>
              <Button color="primary" onClick={() => setCount(count + 10)}>
                Load more...
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ExploreBloodlines;
