import "./Content.css";
import { Container, Row, Col, InputGroup, Input, Button } from "reactstrap";
import { NFT } from "./NFT";
import { NFTData } from "../services/NFTData";
import { useState, useEffect } from "react";

const nftImages = require.context("../img/nft", true);

const ExploreAdaz = () => {
  const [nfts, setNfts] = useState([]);
  const [query, setQuery] = useState("");
  const [count, setCount] = useState(32);

  useEffect(() => {
    setNfts(NFTData);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => (count === 32 ? filter(query) : setCount(32)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    filter(query);
  }, [count]);

  const filter = async (text) => {
    setNfts(
      NFTData.filter(
        (nft) =>
          nft.name.toLowerCase().includes(text) ||
          nft.bloodline.toLowerCase().includes(text) ||
          nft.generation.toString().includes(text) ||
          nft.border.toLowerCase().includes(text)
      ).slice(0, count)
    );
  };

  return (
    <Row>
      <Col className="center-text">
        <p className="Content-text Content-space-side">
          Here you can see all 900 Adaz NFTs. Click an image to view it in full
          resolution on pool.pm!
        </p>
        <Row className="Content-space-side">
          <Col md="3"></Col>
          <Col md="6">
            <InputGroup>
              <Input
                placeholder="search for name, bloodline, generation or border"
                onChange={(event) => setQuery(event.target.value.toLowerCase())}
              />
            </InputGroup>
          </Col>
        </Row>
        <p className="Content-spacer"></p>
        <Row className="center-text Content-space-side">
          {nfts.map((nft, i) => {
            return (
              <Col key={`nft_${i}`} md="3" xs="6">
                <NFT
                  image={
                    nftImages(
                      "./" + nft.name.replace(" ", "").replace(" ", "") + ".png"
                    ).default
                  }
                  name={nft.name}
                  gen={nft.generation}
                  bloodline={nft.bloodline}
                  border={nft.border}
                  url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${nft.name
                    .replace(" ", "")
                    .replace(" ", "")}`}
                />
              </Col>
            );
          })}
        </Row>
        {count <= nfts.length && (
          <Row className="Content-space-side">
            <Col>
              <Button color="primary" onClick={() => setCount(count + 32)}>
                Load more...
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ExploreAdaz;
