import "./Content.css";
import React from "react";

import twitterIcon from "../img/twitter.svg";
import discordIcon from "../img/discord.svg";

const SocialIcons = () => {
  return (
    <div>
      <p className="mt-5">
        <a href="https://twitter.com/AdazNFT" target="_blank" rel="noreferrer">
          <img src={twitterIcon} alt="Twitter" className="Content-icon" />
        </a>
        <span className="mr-4"></span>
        <a
          href="https://discord.gg/DBQJzfdASG"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordIcon} alt="Discord" className="Content-icon" />
        </a>
      </p>
      <p className="Content-spacer"></p>
    </div>
  );
};

export default SocialIcons;
