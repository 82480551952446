// The API server
// export const ApiUrl = "https://api.adaz.xyz/";
export const ApiUrl = "http://localhost:49154/";
// export const ApiUrl = "https://adaz-api-app-tbens.ondigitalocean.app/";

const NftController = "Nft/";

export const CurrentPriceEndpoint = ApiUrl + NftController + "CurrentPrice";

// Reserve an NFT
export const ReserveEndpoint = ApiUrl + NftController + "ReserveRandomNft";

// Check if payment has arrived and mint NFT if true
export const CheckPaymentStateEndpoint =
  ApiUrl + NftController + "CheckPaymentState";

export const Projects = {
  nightmares: 1, // can't use 0 because !0 = True
  adaz: 2,
  bloodlines: 3,
};
