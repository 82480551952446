import "./Content.css";
import { Row, Col, InputGroup, Input, Button } from "reactstrap";
import { NFT } from "./NFT";
import { NFTDataNightmares } from "../services/NFTDataNightmares";
import { useState, useEffect } from "react";

const nftImages = require.context("../img/nightmares", true);

const ExploreNightmares = () => {
  const [nfts, setNfts] = useState([]);
  const [query, setQuery] = useState("");
  const [count, setCount] = useState(32);

  useEffect(() => {
    setNfts(NFTDataNightmares);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => (count === 32 ? filter(query) : setCount(32)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [query]);

  useEffect(() => {
    filter(query);
  }, [count]);

  const filter = async (text) => {
    setNfts(
      NFTDataNightmares.filter((nft) =>
        nft.name.toLowerCase().includes(text)
      ).slice(0, count)
    );
  };

  return (
    <Row>
      <Col className="center-text">
        <p className="Content-text Content-space-side">
          The 100 Adaz Nightmares NFTs were exclusively available for the first
          members of our community. Click an image to view it in full resolution
          on pool.pm!
        </p>
        <Row className="Content-space-side">
          <Col md="3"></Col>
          <Col md="6">
            <InputGroup>
              <Input
                placeholder="search for name"
                onChange={(event) => setQuery(event.target.value.toLowerCase())}
              />
            </InputGroup>
          </Col>
        </Row>
        <p className="Content-spacer"></p>
        <Row className="center-text Content-space-side">
          {nfts.map((nft, i) => {
            return (
              <Col key={`nft_${i}`} md="3" xs="6">
                <NFT
                  image={
                    nftImages(
                      "./" + nft.name.replace(" ", "").replace(" ", "") + ".png"
                    ).default
                  }
                  name={nft.name}
                  gen={nft.generation}
                  bloodline={nft.bloodline}
                  border={nft.border}
                  url={`https://pool.pm/fbfa072b4493c0a17ac42ce9d9146804b342be4f898e269950182240.${nft.name.replace(
                    " ",
                    ""
                  )}`}
                />
              </Col>
            );
          })}
        </Row>
        {count <= nfts.length && (
          <Row className="Content-space-side">
            <Col>
              <Button color="primary" onClick={() => setCount(count + 32)}>
                Load more...
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ExploreNightmares;
