import "./App.css";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import banner from "./img/banner.png";
import Marketplaces from "./components/Marketplaces";
import Explore from "./components/Explore";
import FAQ from "./components/FAQ";
import Policies from "./components/Policies";
import { Projects } from "./services/Constants";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navigation></Navigation>

        <Switch>
          <Route path="/buy">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Buy NFTs</title>
                <meta
                  name="description"
                  content="Get some Adaz NFTs before there are none left!"
                />
              </Helmet>
              <Marketplaces></Marketplaces>
            </Container>
          </Route>

          <Route path="/explore/adaz">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Explore NFTs</title>
                <meta
                  name="description"
                  content="Explore the full collection of Adaz CNFTs."
                />
              </Helmet>
              <Explore project={Projects.adaz}></Explore>
            </Container>
          </Route>

          <Route path="/explore/nightmares">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Explore NFTs</title>
                <meta
                  name="description"
                  content="Explore the full collection of Adaz CNFTs."
                />
              </Helmet>
              <Explore project={Projects.nightmares}></Explore>
            </Container>
          </Route>

          <Route path="/explore/bloodlines">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Explore NFTs</title>
                <meta
                  name="description"
                  content="Explore the full collection of Adaz CNFTs."
                />
              </Helmet>
              <Explore project={Projects.bloodlines}></Explore>
            </Container>
          </Route>

          <Route path="/explore">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Explore NFTs</title>
                <meta
                  name="description"
                  content="Explore the full collection of Adaz CNFTs."
                />
              </Helmet>
              <Explore></Explore>
            </Container>
          </Route>

          <Route path="/faq">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Frequently asked questions</title>
                <meta
                  name="description"
                  content="You should find answers to all questions regarding the project here. If not, get in contact!"
                />
              </Helmet>
              <FAQ></FAQ>
            </Container>
          </Route>

          <Route path="/policies">
            <Container className="App App-space-top">
              <Helmet>
                <title>Adaz | Policy IDs</title>
                <meta
                  name="description"
                  content="The official Cardano policy IDs of our NFTs."
                />
              </Helmet>
              <Policies></Policies>
            </Container>
          </Route>

          <Route path="/">
            {/* The Router stops at the first match.
                path='/' matches always! Therefore it needs to be at the end. */}
            <Container>
              <img src={banner} className="img-fluid" alt="Adaz page banner" />
            </Container>
            <Container className="App">
              <Home></Home>
            </Container>
          </Route>
        </Switch>
      </BrowserRouter>

      <Footer></Footer>
    </div>
  );
}

export default App;
