export const NFTDataNightmares = [
  { name: "Graciela" },
  { name: "Geena" },
  { name: "Ayanna" },
  { name: "Dzintra" },
  { name: "Lili" },
  { name: "Frederique" },
  { name: "Arantxa" },
  { name: "Christi" },
  { name: "Dorrit" },
  { name: "Annette" },
  { name: "Antra" },
  { name: "Grazia" },
  { name: "Jurgita" },
  { name: "Caylee" },
  { name: "Ethlyn" },
  { name: "Lynda" },
  { name: "Acey" },
  { name: "Marjana" },
  { name: "Jemma" },
  { name: "Lorena" },
  { name: "Merike" },
  { name: "Renitta" },
  { name: "Lenka" },
  { name: "Zlata" },
  { name: "Eloise" },
  { name: "Shanique" },
  { name: "India" },
  { name: "Liliane" },
  { name: "Bohumil" },
  { name: "Kristin" },
  { name: "Kunigunde" },
  { name: "Karla" },
  { name: "Maia" },
  { name: "Branka" },
  { name: "Suzie" },
  { name: "Helma" },
  { name: "Laimdota" },
  { name: "Steph" },
  { name: "Jodie" },
  { name: "Ildze" },
  { name: "Alisha" },
  { name: "Lidy" },
  { name: "Latisha" },
  { name: "Lauran" },
  { name: "Alexa" },
  { name: "Sniedze" },
  { name: "Tineke" },
  { name: "Viviana" },
  { name: "Ruby" },
  { name: "Rianne" },
  { name: "Chapa" },
  { name: "Elektra" },
  { name: "Rimante" },
  { name: "Dionne" },
  { name: "Felicia" },
  { name: "Magnhild" },
  { name: "Taylor" },
  { name: "Ailsa" },
  { name: "Kertu" },
  { name: "Julia" },
  { name: "Hyacinth" },
  { name: "Eivor" },
  { name: "Audrey" },
  { name: "Eirwen" },
  { name: "Padmaja" },
  { name: "Aurea" },
  { name: "Giedre" },
  { name: "Trisha" },
  { name: "Leonore" },
  { name: "Bridgette" },
  { name: "Laisa" },
  { name: "Nanci" },
  { name: "Kirsty" },
  { name: "Hildur" },
  { name: "Tekla" },
  { name: "Viktoria" },
  { name: "Acie" },
  { name: "Lottie" },
  { name: "Annot" },
  { name: "Alyssa" },
  { name: "Yvonne" },
  { name: "Grace" },
  { name: "Andrijana" },
  { name: "Zanda" },
  { name: "Emelie" },
  { name: "Meri" },
  { name: "Ulla" },
  { name: "Alessandra" },
  { name: "Jacinta" },
  { name: "Albina" },
  { name: "Indra" },
  { name: "Freya" },
  { name: "Cairine" },
  { name: "Mietje" },
  { name: "Evelin" },
  { name: "Anna" },
  { name: "Cleopatra" },
  { name: "Flora" },
  { name: "Aletta" },
  { name: "Leanne" },
];
