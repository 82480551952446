import "./Content.css";
import { Col } from "reactstrap";
import { NFT } from "./NFT";
const nftImages = require.context("../img/nft", true);
const animatedNftImages = require.context("../img/animated", true);

const Bloodline = (props) => {
  const bloodline =
    props.nfts.gen1.bloodline.charAt(0).toUpperCase() +
    props.nfts.gen1.bloodline.slice(1).replace(" ", "").replace(" ", "") +
    "Bloodline";
  return (
    <>
      <Col key="title" xs="12">
        <h3>{bloodline.replace("Bloodline", " Bloodline")}</h3>
        <p></p>
      </Col>
      <Col md="0" xs="1"></Col>
      <Col key={`nft_gen1`} md="2" xs="2">
        <NFT
          image={
            nftImages(
              "./" +
                props.nfts.gen1.name.replace(" ", "").replace(" ", "") +
                ".png"
            ).default
          }
          url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${props.nfts.gen1.name
            .replace(" ", "")
            .replace(" ", "")}`}
        />
      </Col>
      <Col key={`nft_gen2`} md="2" xs="2">
        <NFT
          image={
            nftImages(
              "./" +
                props.nfts.gen2.name.replace(" ", "").replace(" ", "") +
                ".png"
            ).default
          }
          url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${props.nfts.gen2.name
            .replace(" ", "")
            .replace(" ", "")}`}
        />
      </Col>
      <Col key={`nft_gen3`} md="2" xs="2">
        <NFT
          image={
            nftImages(
              "./" +
                props.nfts.gen3.name.replace(" ", "").replace(" ", "") +
                ".png"
            ).default
          }
          url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${props.nfts.gen3.name
            .replace(" ", "")
            .replace(" ", "")}`}
        />
      </Col>
      <Col key={`nft_gen4`} md="2" xs="2">
        <NFT
          image={
            nftImages(
              "./" +
                props.nfts.gen4.name.replace(" ", "").replace(" ", "") +
                ".png"
            ).default
          }
          url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${props.nfts.gen4.name
            .replace(" ", "")
            .replace(" ", "")}`}
        />
      </Col>
      <Col key={`nft_gen5`} md="2" xs="2">
        <NFT
          image={
            nftImages(
              "./" +
                props.nfts.gen5.name.replace(" ", "").replace(" ", "") +
                ".png"
            ).default
          }
          url={`https://pool.pm/6ff760ad976cefc52a5ffbffd884a7ed7022fba6cc2d61643d45f6cd.${props.nfts.gen5.name
            .replace(" ", "")
            .replace(" ", "")}`}
        />
      </Col>
      <Col md="0" xs="1"></Col>
      <Col key={`nft_ada`} md="12" xs="12">
        <NFT
          sub={
            props.nfts.gen1.completed === "TRUE" &&
            "Click to view animation on pool.pm"
          }
          image={
            props.nfts.gen1.completed === "TRUE"
              ? animatedNftImages(
                  "./" + props.nfts.gen1.bloodline + "_thumbnail.jpg"
                ).default
              : animatedNftImages("./none.jpg").default
          }
          url={`https://pool.pm/7a245d37eef9081474eed91f351c9149d3014f451681b43a8474031f.${bloodline}`}
        />
      </Col>
    </>
  );
};

export default Bloodline;
