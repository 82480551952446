import "../Content.css";
import React from "react";

const Twitter = () => (
  <a href="https://twitter.com/AdazNFT" target="_blank" rel="noreferrer">
    Twitter
  </a>
);

export default Twitter;
