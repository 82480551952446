import "./Content.css";
import "./QuestionAnswer.css";
import React, { useState } from "react";
import { Card, CardText, CardBody, CardTitle, Collapse } from "reactstrap";
import arrowDownIcon from "../img/arrow_down.svg";

const QuestionAnswer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Card className="Qa-card">
      <CardBody className="Clickable" onClick={toggle}>
        <CardTitle className="Qa-title" tag="h3">
          {props.question}
        </CardTitle>
        <img
          src={arrowDownIcon}
          alt="Open answer"
          className={(isOpen ? "Arrow-up" : "Arrow-down") + " Arrow-icon"}
        />
        <Collapse isOpen={isOpen}>
          <CardText className="Content-text Content-space-side">
            {props.answer}
          </CardText>
          {props.image != null ? (
            <img
              src={props.image}
              alt="Open answer"
              className="Content-full-width-image"
            />
          ) : (
            ""
          )}
        </Collapse>
      </CardBody>
    </Card>
  );
};

export default QuestionAnswer;
