import "./Footer.css";
import banner from "../img/banner.png";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <img
              className="Footer-logo img-fluid"
              src={banner}
              alt="Adaz banner logo"
            />
          </Col>
        </Row>
        <Row>
          <Col>Copyright © {new Date().getFullYear()} adaz.xyz </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
