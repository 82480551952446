export const NFTData = [
  {
    name: "Augusta Ada King",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ada Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lorinda Lovelace",
    border: "azure-lime",
    generation: 1,
    bloodline: "alive",
    completed: "FALSE",
  },
  {
    name: "Katrin Lovelace",
    border: "azure-lime",
    generation: 2,
    bloodline: "alive",
    completed: "FALSE",
  },
  {
    name: "Dakota Lovelace",
    border: "azure-lime",
    generation: 3,
    bloodline: "alive",
    completed: "FALSE",
  },
  {
    name: "Ernestine Lovelace",
    border: "azure-lime",
    generation: 4,
    bloodline: "alive",
    completed: "FALSE",
  },
  {
    name: "Ingrida Lovelace",
    border: "azure-lime",
    generation: 5,
    bloodline: "alive",
    completed: "FALSE",
  },
  {
    name: "Linda Lovelace",
    border: "blue-cyan",
    generation: 1,
    bloodline: "determined",
    completed: "FALSE",
  },
  {
    name: "Lasma Lovelace",
    border: "blue-cyan",
    generation: 2,
    bloodline: "determined",
    completed: "FALSE",
  },
  {
    name: "Charlene Lovelace",
    border: "blue-cyan",
    generation: 3,
    bloodline: "determined",
    completed: "FALSE",
  },
  {
    name: "Piper Lovelace",
    border: "blue-cyan",
    generation: 4,
    bloodline: "determined",
    completed: "FALSE",
  },
  {
    name: "Ilka Lovelace",
    border: "blue-cyan",
    generation: 5,
    bloodline: "determined",
    completed: "FALSE",
  },
  {
    name: "Magdalene Lovelace",
    border: "blue-peach",
    generation: 1,
    bloodline: "clever",
    completed: "FALSE",
  },
  {
    name: "Aimone Lovelace",
    border: "blue-peach",
    generation: 2,
    bloodline: "clever",
    completed: "FALSE",
  },
  {
    name: "Meredith Lovelace",
    border: "blue-peach",
    generation: 3,
    bloodline: "clever",
    completed: "FALSE",
  },
  {
    name: "Izolda Lovelace",
    border: "blue-peach",
    generation: 4,
    bloodline: "clever",
    completed: "FALSE",
  },
  {
    name: "Jette Lovelace",
    border: "blue-peach",
    generation: 5,
    bloodline: "clever",
    completed: "FALSE",
  },
  {
    name: "Mara Lovelace",
    border: "blue-purple",
    generation: 1,
    bloodline: "stormy",
    completed: "FALSE",
  },
  {
    name: "Akosua Lovelace",
    border: "blue-purple",
    generation: 2,
    bloodline: "stormy",
    completed: "FALSE",
  },
  {
    name: "Alia Lovelace",
    border: "blue-purple",
    generation: 3,
    bloodline: "stormy",
    completed: "FALSE",
  },
  {
    name: "Tutta Lovelace",
    border: "blue-purple",
    generation: 4,
    bloodline: "stormy",
    completed: "FALSE",
  },
  {
    name: "Grete Lovelace",
    border: "blue-purple",
    generation: 5,
    bloodline: "stormy",
    completed: "FALSE",
  },
  {
    name: "Zena Lovelace",
    border: "blue-yellow",
    generation: 1,
    bloodline: "wild",
    completed: "TRUE",
  },
  {
    name: "Nelli Lovelace",
    border: "blue-yellow",
    generation: 2,
    bloodline: "wild",
    completed: "FALSE",
  },
  {
    name: "Ahhotep Lovelace",
    border: "blue-yellow",
    generation: 3,
    bloodline: "wild",
    completed: "FALSE",
  },
  {
    name: "Cathie Lovelace",
    border: "blue-yellow",
    generation: 4,
    bloodline: "wild",
    completed: "FALSE",
  },
  {
    name: "Shanzay Lovelace",
    border: "blue-yellow",
    generation: 5,
    bloodline: "wild",
    completed: "FALSE",
  },
  {
    name: "Paulina Lovelace",
    border: "pink-cyan",
    generation: 1,
    bloodline: "cold",
    completed: "FALSE",
  },
  {
    name: "Catharine Lovelace",
    border: "pink-cyan",
    generation: 2,
    bloodline: "cold",
    completed: "FALSE",
  },
  {
    name: "Alycia Lovelace",
    border: "pink-cyan",
    generation: 3,
    bloodline: "cold",
    completed: "FALSE",
  },
  {
    name: "Ariel Lovelace",
    border: "pink-cyan",
    generation: 4,
    bloodline: "cold",
    completed: "FALSE",
  },
  {
    name: "Lynnette Lovelace",
    border: "pink-cyan",
    generation: 5,
    bloodline: "cold",
    completed: "FALSE",
  },
  {
    name: "Joice Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "proud",
    completed: "FALSE",
  },
  {
    name: "Astrid Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "proud",
    completed: "FALSE",
  },
  {
    name: "Noella Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "proud",
    completed: "FALSE",
  },
  {
    name: "Ina Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "proud",
    completed: "FALSE",
  },
  {
    name: "Joya Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "proud",
    completed: "FALSE",
  },
  {
    name: "Karen Lovelace",
    border: "champagne",
    generation: 1,
    bloodline: "calm",
    completed: "FALSE",
  },
  {
    name: "Agboola Lovelace",
    border: "champagne",
    generation: 2,
    bloodline: "calm",
    completed: "FALSE",
  },
  {
    name: "Scarlett Lovelace",
    border: "champagne",
    generation: 3,
    bloodline: "calm",
    completed: "FALSE",
  },
  {
    name: "Sian Lovelace",
    border: "champagne",
    generation: 4,
    bloodline: "calm",
    completed: "FALSE",
  },
  {
    name: "Marianne Lovelace",
    border: "champagne",
    generation: 5,
    bloodline: "calm",
    completed: "FALSE",
  },
  {
    name: "Elke Lovelace",
    border: "cornflower",
    generation: 1,
    bloodline: "vivacious",
    completed: "FALSE",
  },
  {
    name: "Marjie Lovelace",
    border: "cornflower",
    generation: 2,
    bloodline: "vivacious",
    completed: "FALSE",
  },
  {
    name: "Fay Lovelace",
    border: "cornflower",
    generation: 3,
    bloodline: "vivacious",
    completed: "FALSE",
  },
  {
    name: "Anitra Lovelace",
    border: "cornflower",
    generation: 4,
    bloodline: "vivacious",
    completed: "FALSE",
  },
  {
    name: "Pablita Lovelace",
    border: "cornflower",
    generation: 5,
    bloodline: "vivacious",
    completed: "FALSE",
  },
  {
    name: "Charlize Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "elegant",
    completed: "FALSE",
  },
  {
    name: "Lorelei Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "elegant",
    completed: "FALSE",
  },
  {
    name: "Inez Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "elegant",
    completed: "FALSE",
  },
  {
    name: "Karin Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "elegant",
    completed: "FALSE",
  },
  {
    name: "Aliza Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "elegant",
    completed: "FALSE",
  },
  {
    name: "Kamie Lovelace",
    border: "fire",
    generation: 1,
    bloodline: "graceful",
    completed: "FALSE",
  },
  {
    name: "Martine Lovelace",
    border: "fire",
    generation: 2,
    bloodline: "graceful",
    completed: "FALSE",
  },
  {
    name: "Millie Lovelace",
    border: "fire",
    generation: 3,
    bloodline: "graceful",
    completed: "FALSE",
  },
  {
    name: "Brett Lovelace",
    border: "fire",
    generation: 4,
    bloodline: "graceful",
    completed: "FALSE",
  },
  {
    name: "Mariann Lovelace",
    border: "fire",
    generation: 5,
    bloodline: "graceful",
    completed: "FALSE",
  },
  {
    name: "Denyse Lovelace",
    border: "raspberry-yellow",
    generation: 1,
    bloodline: "victorious",
    completed: "FALSE",
  },
  {
    name: "Anabelle Lovelace",
    border: "raspberry-yellow",
    generation: 2,
    bloodline: "victorious",
    completed: "FALSE",
  },
  {
    name: "Madhavi Lovelace",
    border: "raspberry-yellow",
    generation: 3,
    bloodline: "victorious",
    completed: "FALSE",
  },
  {
    name: "Edith Lovelace",
    border: "raspberry-yellow",
    generation: 4,
    bloodline: "victorious",
    completed: "FALSE",
  },
  {
    name: "Renesmee Lovelace",
    border: "raspberry-yellow",
    generation: 5,
    bloodline: "victorious",
    completed: "FALSE",
  },
  {
    name: "Tessie Lovelace",
    border: "violet-peach",
    generation: 1,
    bloodline: "?464",
    completed: "FALSE",
  },
  {
    name: "Arielle Lovelace",
    border: "violet-peach",
    generation: 2,
    bloodline: "?464",
    completed: "FALSE",
  },
  {
    name: "Marita Lovelace",
    border: "violet-peach",
    generation: 3,
    bloodline: "?464",
    completed: "FALSE",
  },
  {
    name: "Nina Lovelace",
    border: "violet-peach",
    generation: 4,
    bloodline: "?464",
    completed: "FALSE",
  },
  {
    name: "Cynthia Lovelace",
    border: "violet-peach",
    generation: 5,
    bloodline: "?464",
    completed: "FALSE",
  },
  {
    name: "Jolanta Lovelace",
    border: "olive",
    generation: 1,
    bloodline: "adventurous",
    completed: "FALSE",
  },
  {
    name: "Mallerie Lovelace",
    border: "olive",
    generation: 2,
    bloodline: "adventurous",
    completed: "FALSE",
  },
  {
    name: "Liia Lovelace",
    border: "olive",
    generation: 3,
    bloodline: "adventurous",
    completed: "FALSE",
  },
  {
    name: "Phebe Lovelace",
    border: "olive",
    generation: 4,
    bloodline: "adventurous",
    completed: "FALSE",
  },
  {
    name: "Esta Lovelace",
    border: "olive",
    generation: 5,
    bloodline: "adventurous",
    completed: "FALSE",
  },
  {
    name: "Lark Lovelace",
    border: "pear",
    generation: 1,
    bloodline: "crazy",
    completed: "TRUE",
  },
  {
    name: "Iwona Lovelace",
    border: "pear",
    generation: 2,
    bloodline: "crazy",
    completed: "FALSE",
  },
  {
    name: "Leni Lovelace",
    border: "pear",
    generation: 3,
    bloodline: "crazy",
    completed: "FALSE",
  },
  {
    name: "Hetty Lovelace",
    border: "pear",
    generation: 4,
    bloodline: "crazy",
    completed: "FALSE",
  },
  {
    name: "Alda Lovelace",
    border: "pear",
    generation: 5,
    bloodline: "crazy",
    completed: "FALSE",
  },
  {
    name: "Fazila Lovelace",
    border: "orange-pink",
    generation: 1,
    bloodline: "brave",
    completed: "FALSE",
  },
  {
    name: "Alfonsina Lovelace",
    border: "orange-pink",
    generation: 2,
    bloodline: "brave",
    completed: "FALSE",
  },
  {
    name: "Arta Lovelace",
    border: "orange-pink",
    generation: 3,
    bloodline: "brave",
    completed: "FALSE",
  },
  {
    name: "Stefi Lovelace",
    border: "orange-pink",
    generation: 4,
    bloodline: "brave",
    completed: "FALSE",
  },
  {
    name: "Tammy Lovelace",
    border: "orange-pink",
    generation: 5,
    bloodline: "brave",
    completed: "FALSE",
  },
  {
    name: "Mickey Lovelace",
    border: "peach",
    generation: 1,
    bloodline: "happy",
    completed: "FALSE",
  },
  {
    name: "Sidonia Lovelace",
    border: "peach",
    generation: 2,
    bloodline: "happy",
    completed: "FALSE",
  },
  {
    name: "Latoya Lovelace",
    border: "peach",
    generation: 3,
    bloodline: "happy",
    completed: "FALSE",
  },
  {
    name: "Avice Lovelace",
    border: "peach",
    generation: 4,
    bloodline: "happy",
    completed: "FALSE",
  },
  {
    name: "Lilo Lovelace",
    border: "peach",
    generation: 5,
    bloodline: "happy",
    completed: "FALSE",
  },
  {
    name: "Dorcas Lovelace",
    border: "white-gold",
    generation: 1,
    bloodline: "gifted",
    completed: "FALSE",
  },
  {
    name: "Merilyn Lovelace",
    border: "white-gold",
    generation: 2,
    bloodline: "gifted",
    completed: "FALSE",
  },
  {
    name: "Amalia Lovelace",
    border: "white-gold",
    generation: 3,
    bloodline: "gifted",
    completed: "FALSE",
  },
  {
    name: "Alma Lovelace",
    border: "white-gold",
    generation: 4,
    bloodline: "gifted",
    completed: "FALSE",
  },
  {
    name: "Crista Lovelace",
    border: "white-gold",
    generation: 5,
    bloodline: "gifted",
    completed: "FALSE",
  },
  {
    name: "Giuliana Lovelace",
    border: "pink-cyan",
    generation: 1,
    bloodline: "powerful",
    completed: "TRUE",
  },
  {
    name: "Inese Lovelace",
    border: "pink-cyan",
    generation: 2,
    bloodline: "powerful",
    completed: "TRUE",
  },
  {
    name: "Elayne Lovelace",
    border: "pink-cyan",
    generation: 3,
    bloodline: "powerful",
    completed: "TRUE",
  },
  {
    name: "Meggie Lovelace",
    border: "pink-cyan",
    generation: 4,
    bloodline: "powerful",
    completed: "TRUE",
  },
  {
    name: "Jerri Lovelace",
    border: "pink-cyan",
    generation: 5,
    bloodline: "powerful",
    completed: "TRUE",
  },
  {
    name: "Minnie Lovelace",
    border: "purple",
    generation: 1,
    bloodline: "driven",
    completed: "FALSE",
  },
  {
    name: "Penny Lovelace",
    border: "purple",
    generation: 2,
    bloodline: "driven",
    completed: "FALSE",
  },
  {
    name: "Gretta Lovelace",
    border: "purple",
    generation: 3,
    bloodline: "driven",
    completed: "FALSE",
  },
  {
    name: "Jacquie Lovelace",
    border: "purple",
    generation: 4,
    bloodline: "driven",
    completed: "FALSE",
  },
  {
    name: "Yuliana Lovelace",
    border: "purple",
    generation: 5,
    bloodline: "driven",
    completed: "FALSE",
  },
  {
    name: "Meseret Lovelace",
    border: "purple-green",
    generation: 1,
    bloodline: "mysterious",
    completed: "TRUE",
  },
  {
    name: "Luitgard Lovelace",
    border: "purple-green",
    generation: 2,
    bloodline: "mysterious",
    completed: "FALSE",
  },
  {
    name: "Elsa Lovelace",
    border: "purple-green",
    generation: 3,
    bloodline: "mysterious",
    completed: "FALSE",
  },
  {
    name: "Dena Lovelace",
    border: "purple-green",
    generation: 4,
    bloodline: "mysterious",
    completed: "FALSE",
  },
  {
    name: "Tanisha Lovelace",
    border: "purple-green",
    generation: 5,
    bloodline: "mysterious",
    completed: "FALSE",
  },
  {
    name: "Lovisa Lovelace",
    border: "raspberry-yellow",
    generation: 1,
    bloodline: "jolly",
    completed: "FALSE",
  },
  {
    name: "Cilla Lovelace",
    border: "raspberry-yellow",
    generation: 2,
    bloodline: "jolly",
    completed: "FALSE",
  },
  {
    name: "Alba Lovelace",
    border: "raspberry-yellow",
    generation: 3,
    bloodline: "jolly",
    completed: "FALSE",
  },
  {
    name: "Cally Lovelace",
    border: "raspberry-yellow",
    generation: 4,
    bloodline: "jolly",
    completed: "FALSE",
  },
  {
    name: "Brilliana Lovelace",
    border: "raspberry-yellow",
    generation: 5,
    bloodline: "jolly",
    completed: "FALSE",
  },
  {
    name: "Fernande Lovelace",
    border: "red-blue",
    generation: 1,
    bloodline: "fiery",
    completed: "FALSE",
  },
  {
    name: "Jasmine Lovelace",
    border: "red-blue",
    generation: 2,
    bloodline: "fiery",
    completed: "FALSE",
  },
  {
    name: "Hilary Lovelace",
    border: "red-blue",
    generation: 3,
    bloodline: "fiery",
    completed: "FALSE",
  },
  {
    name: "Adama Lovelace",
    border: "red-blue",
    generation: 4,
    bloodline: "fiery",
    completed: "FALSE",
  },
  {
    name: "Katryna Lovelace",
    border: "red-blue",
    generation: 5,
    bloodline: "fiery",
    completed: "FALSE",
  },
  {
    name: "Trish Lovelace",
    border: "cerulean-citrine",
    generation: 1,
    bloodline: "dangerous",
    completed: "TRUE",
  },
  {
    name: "Eliza Lovelace",
    border: "cerulean-citrine",
    generation: 2,
    bloodline: "dangerous",
    completed: "FALSE",
  },
  {
    name: "Larissa Lovelace",
    border: "cerulean-citrine",
    generation: 3,
    bloodline: "dangerous",
    completed: "FALSE",
  },
  {
    name: "Adee Lovelace",
    border: "cerulean-citrine",
    generation: 4,
    bloodline: "dangerous",
    completed: "FALSE",
  },
  {
    name: "Aina Lovelace",
    border: "cerulean-citrine",
    generation: 5,
    bloodline: "dangerous",
    completed: "FALSE",
  },
  {
    name: "Katherine Lovelace",
    border: "red-pink",
    generation: 1,
    bloodline: "charming",
    completed: "TRUE",
  },
  {
    name: "Leyna Lovelace",
    border: "red-pink",
    generation: 2,
    bloodline: "charming",
    completed: "TRUE",
  },
  {
    name: "Roda Lovelace",
    border: "red-pink",
    generation: 3,
    bloodline: "charming",
    completed: "TRUE",
  },
  {
    name: "Edeltraud Lovelace",
    border: "red-pink",
    generation: 4,
    bloodline: "charming",
    completed: "TRUE",
  },
  {
    name: "Jen Lovelace",
    border: "red-pink",
    generation: 5,
    bloodline: "charming",
    completed: "TRUE",
  },
  {
    name: "Britnee Lovelace",
    border: "red-yellow",
    generation: 1,
    bloodline: "clumsy",
    completed: "FALSE",
  },
  {
    name: "Aline Lovelace",
    border: "red-yellow",
    generation: 2,
    bloodline: "clumsy",
    completed: "FALSE",
  },
  {
    name: "Athena Lovelace",
    border: "red-yellow",
    generation: 3,
    bloodline: "clumsy",
    completed: "FALSE",
  },
  {
    name: "Rolanda Lovelace",
    border: "red-yellow",
    generation: 4,
    bloodline: "clumsy",
    completed: "FALSE",
  },
  {
    name: "Malvina Lovelace",
    border: "red-yellow",
    generation: 5,
    bloodline: "clumsy",
    completed: "FALSE",
  },
  {
    name: "Georgeanna Lovelace",
    border: "shiny-bronze",
    generation: 1,
    bloodline: "courageous",
    completed: "TRUE",
  },
  {
    name: "Mercy Lovelace",
    border: "shiny-bronze",
    generation: 2,
    bloodline: "courageous",
    completed: "FALSE",
  },
  {
    name: "Sasikala Lovelace",
    border: "shiny-bronze",
    generation: 3,
    bloodline: "courageous",
    completed: "FALSE",
  },
  {
    name: "Mahsa Lovelace",
    border: "shiny-bronze",
    generation: 4,
    bloodline: "courageous",
    completed: "FALSE",
  },
  {
    name: "Liesl Lovelace",
    border: "shiny-bronze",
    generation: 5,
    bloodline: "courageous",
    completed: "FALSE",
  },
  {
    name: "Linnie Lovelace",
    border: "violet-peach",
    generation: 1,
    bloodline: "funny",
    completed: "TRUE",
  },
  {
    name: "Flore Lovelace",
    border: "violet-peach",
    generation: 2,
    bloodline: "funny",
    completed: "TRUE",
  },
  {
    name: "Emmy Lovelace",
    border: "violet-peach",
    generation: 3,
    bloodline: "funny",
    completed: "TRUE",
  },
  {
    name: "Hazelle Lovelace",
    border: "violet-peach",
    generation: 4,
    bloodline: "funny",
    completed: "TRUE",
  },
  {
    name: "Shriya Lovelace",
    border: "violet-peach",
    generation: 5,
    bloodline: "funny",
    completed: "TRUE",
  },
  {
    name: "Jolie Lovelace",
    border: "white",
    generation: 1,
    bloodline: "grumpy",
    completed: "FALSE",
  },
  {
    name: "Margit Lovelace",
    border: "white",
    generation: 2,
    bloodline: "grumpy",
    completed: "FALSE",
  },
  {
    name: "Ibtissam Lovelace",
    border: "white",
    generation: 3,
    bloodline: "grumpy",
    completed: "FALSE",
  },
  {
    name: "Ilana Lovelace",
    border: "white",
    generation: 4,
    bloodline: "grumpy",
    completed: "FALSE",
  },
  {
    name: "Vlasta Lovelace",
    border: "white",
    generation: 5,
    bloodline: "grumpy",
    completed: "FALSE",
  },
  {
    name: "Antonella Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "scarred",
    completed: "FALSE",
  },
  {
    name: "Dottie Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "scarred",
    completed: "FALSE",
  },
  {
    name: "Rachel Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "scarred",
    completed: "FALSE",
  },
  {
    name: "Celestia Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "scarred",
    completed: "FALSE",
  },
  {
    name: "Natallia Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "scarred",
    completed: "FALSE",
  },
  {
    name: "Rhiannon Lovelace",
    border: "azure-lime",
    generation: 1,
    bloodline: "enchanting",
    completed: "FALSE",
  },
  {
    name: "Ivone Lovelace",
    border: "azure-lime",
    generation: 2,
    bloodline: "enchanting",
    completed: "FALSE",
  },
  {
    name: "Philippa Lovelace",
    border: "azure-lime",
    generation: 3,
    bloodline: "enchanting",
    completed: "FALSE",
  },
  {
    name: "Adia Lovelace",
    border: "azure-lime",
    generation: 4,
    bloodline: "enchanting",
    completed: "FALSE",
  },
  {
    name: "Myriane Lovelace",
    border: "azure-lime",
    generation: 5,
    bloodline: "enchanting",
    completed: "FALSE",
  },
  {
    name: "Adrianne Lovelace",
    border: "blue-cyan",
    generation: 1,
    bloodline: "sparkling",
    completed: "FALSE",
  },
  {
    name: "Kaye Lovelace",
    border: "blue-cyan",
    generation: 2,
    bloodline: "sparkling",
    completed: "FALSE",
  },
  {
    name: "Grazina Lovelace",
    border: "blue-cyan",
    generation: 3,
    bloodline: "sparkling",
    completed: "FALSE",
  },
  {
    name: "Evi Lovelace",
    border: "blue-cyan",
    generation: 4,
    bloodline: "sparkling",
    completed: "FALSE",
  },
  {
    name: "Delilah Lovelace",
    border: "blue-cyan",
    generation: 5,
    bloodline: "sparkling",
    completed: "FALSE",
  },
  {
    name: "Demi Lovelace",
    border: "blue-peach",
    generation: 1,
    bloodline: "dizzy",
    completed: "FALSE",
  },
  {
    name: "Alyson Lovelace",
    border: "blue-peach",
    generation: 2,
    bloodline: "dizzy",
    completed: "FALSE",
  },
  {
    name: "Shanelle Lovelace",
    border: "blue-peach",
    generation: 3,
    bloodline: "dizzy",
    completed: "FALSE",
  },
  {
    name: "Irmela Lovelace",
    border: "blue-peach",
    generation: 4,
    bloodline: "dizzy",
    completed: "FALSE",
  },
  {
    name: "Draginja Lovelace",
    border: "blue-peach",
    generation: 5,
    bloodline: "dizzy",
    completed: "FALSE",
  },
  {
    name: "Maite Lovelace",
    border: "blue-purple",
    generation: 1,
    bloodline: "quaint",
    completed: "FALSE",
  },
  {
    name: "Agrippina Lovelace",
    border: "blue-purple",
    generation: 2,
    bloodline: "quaint",
    completed: "FALSE",
  },
  {
    name: "Brittny Lovelace",
    border: "blue-purple",
    generation: 3,
    bloodline: "quaint",
    completed: "FALSE",
  },
  {
    name: "Pearl Lovelace",
    border: "blue-purple",
    generation: 4,
    bloodline: "quaint",
    completed: "FALSE",
  },
  {
    name: "Rosina Lovelace",
    border: "blue-purple",
    generation: 5,
    bloodline: "quaint",
    completed: "FALSE",
  },
  {
    name: "Bolette Lovelace",
    border: "blue-yellow",
    generation: 1,
    bloodline: "friendly",
    completed: "FALSE",
  },
  {
    name: "Naiara Lovelace",
    border: "blue-yellow",
    generation: 2,
    bloodline: "friendly",
    completed: "FALSE",
  },
  {
    name: "Aleena Lovelace",
    border: "blue-yellow",
    generation: 3,
    bloodline: "friendly",
    completed: "FALSE",
  },
  {
    name: "Euphemia Lovelace",
    border: "blue-yellow",
    generation: 4,
    bloodline: "friendly",
    completed: "FALSE",
  },
  {
    name: "Bonita Lovelace",
    border: "blue-yellow",
    generation: 5,
    bloodline: "friendly",
    completed: "FALSE",
  },
  {
    name: "Jessica Lovelace",
    border: "cerulean-citrine",
    generation: 1,
    bloodline: "angry",
    completed: "FALSE",
  },
  {
    name: "Cath Lovelace",
    border: "cerulean-citrine",
    generation: 2,
    bloodline: "angry",
    completed: "FALSE",
  },
  {
    name: "Caden Lovelace",
    border: "cerulean-citrine",
    generation: 3,
    bloodline: "angry",
    completed: "FALSE",
  },
  {
    name: "Mona Lovelace",
    border: "cerulean-citrine",
    generation: 4,
    bloodline: "angry",
    completed: "FALSE",
  },
  {
    name: "Lanette Lovelace",
    border: "cerulean-citrine",
    generation: 5,
    bloodline: "angry",
    completed: "FALSE",
  },
  {
    name: "Ilene Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "curious",
    completed: "TRUE",
  },
  {
    name: "Sonika Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "curious",
    completed: "FALSE",
  },
  {
    name: "Beth Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "curious",
    completed: "FALSE",
  },
  {
    name: "Marg Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "curious",
    completed: "FALSE",
  },
  {
    name: "Avie Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "curious",
    completed: "FALSE",
  },
  {
    name: "Rada Lovelace",
    border: "champagne",
    generation: 1,
    bloodline: "shy",
    completed: "FALSE",
  },
  {
    name: "Dunja Lovelace",
    border: "champagne",
    generation: 2,
    bloodline: "shy",
    completed: "FALSE",
  },
  {
    name: "Tonia Lovelace",
    border: "champagne",
    generation: 3,
    bloodline: "shy",
    completed: "FALSE",
  },
  {
    name: "Anais Lovelace",
    border: "champagne",
    generation: 4,
    bloodline: "shy",
    completed: "FALSE",
  },
  {
    name: "Judi Lovelace",
    border: "champagne",
    generation: 5,
    bloodline: "shy",
    completed: "FALSE",
  },
  {
    name: "Janee Lovelace",
    border: "cornflower",
    generation: 1,
    bloodline: "cheerful",
    completed: "FALSE",
  },
  {
    name: "Hildegard Lovelace",
    border: "cornflower",
    generation: 2,
    bloodline: "cheerful",
    completed: "FALSE",
  },
  {
    name: "Agustina Lovelace",
    border: "cornflower",
    generation: 3,
    bloodline: "cheerful",
    completed: "FALSE",
  },
  {
    name: "Aila Lovelace",
    border: "cornflower",
    generation: 4,
    bloodline: "cheerful",
    completed: "FALSE",
  },
  {
    name: "Monica Lovelace",
    border: "cornflower",
    generation: 5,
    bloodline: "cheerful",
    completed: "FALSE",
  },
  {
    name: "Fien Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "faithful",
    completed: "FALSE",
  },
  {
    name: "Stephie Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "faithful",
    completed: "FALSE",
  },
  {
    name: "Tabitha Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "faithful",
    completed: "FALSE",
  },
  {
    name: "Karina Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "faithful",
    completed: "FALSE",
  },
  {
    name: "Cosette Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "faithful",
    completed: "FALSE",
  },
  {
    name: "Traute Lovelace",
    border: "fire",
    generation: 1,
    bloodline: "joyous",
    completed: "FALSE",
  },
  {
    name: "Milena Lovelace",
    border: "fire",
    generation: 2,
    bloodline: "joyous",
    completed: "FALSE",
  },
  {
    name: "Annabella Lovelace",
    border: "fire",
    generation: 3,
    bloodline: "joyous",
    completed: "FALSE",
  },
  {
    name: "Annalena Lovelace",
    border: "fire",
    generation: 4,
    bloodline: "joyous",
    completed: "FALSE",
  },
  {
    name: "Francisca Lovelace",
    border: "fire",
    generation: 5,
    bloodline: "joyous",
    completed: "FALSE",
  },
  {
    name: "Sheryl Lovelace",
    border: "white",
    generation: 1,
    bloodline: "gentle",
    completed: "FALSE",
  },
  {
    name: "Uliana Lovelace",
    border: "white",
    generation: 2,
    bloodline: "gentle",
    completed: "FALSE",
  },
  {
    name: "Janaki Lovelace",
    border: "white",
    generation: 3,
    bloodline: "gentle",
    completed: "FALSE",
  },
  {
    name: "Maddy Lovelace",
    border: "white",
    generation: 4,
    bloodline: "gentle",
    completed: "FALSE",
  },
  {
    name: "Ausma Lovelace",
    border: "white",
    generation: 5,
    bloodline: "gentle",
    completed: "FALSE",
  },
  {
    name: "Beatrix Lovelace",
    border: "moss",
    generation: 1,
    bloodline: "misty",
    completed: "TRUE",
  },
  {
    name: "Abou Lovelace",
    border: "moss",
    generation: 2,
    bloodline: "misty",
    completed: "FALSE",
  },
  {
    name: "Roxann Lovelace",
    border: "moss",
    generation: 3,
    bloodline: "misty",
    completed: "FALSE",
  },
  {
    name: "Fabia Lovelace",
    border: "moss",
    generation: 4,
    bloodline: "misty",
    completed: "FALSE",
  },
  {
    name: "Marie Lovelace",
    border: "moss",
    generation: 5,
    bloodline: "misty",
    completed: "FALSE",
  },
  {
    name: "Allene Lovelace",
    border: "olive",
    generation: 1,
    bloodline: "tough",
    completed: "TRUE", // Reserved
  },
  {
    name: "Courtney Lovelace",
    border: "olive",
    generation: 2,
    bloodline: "tough",
    completed: "TRUE",
  },
  {
    name: "Sally Lovelace",
    border: "olive",
    generation: 3,
    bloodline: "tough",
    completed: "TRUE",
  },
  {
    name: "Judy Lovelace",
    border: "olive",
    generation: 4,
    bloodline: "tough",
    completed: "TRUE",
  },
  {
    name: "Izaro Lovelace",
    border: "olive",
    generation: 5,
    bloodline: "tough",
    completed: "TRUE",
  },
  {
    name: "Edda Lovelace",
    border: "orange",
    generation: 1,
    bloodline: "smart",
    completed: "FALSE",
  },
  {
    name: "Phyllida Lovelace",
    border: "orange",
    generation: 2,
    bloodline: "smart",
    completed: "FALSE",
  },
  {
    name: "Jayda Lovelace",
    border: "orange",
    generation: 3,
    bloodline: "smart",
    completed: "FALSE",
  },
  {
    name: "Abigail Lovelace",
    border: "orange",
    generation: 4,
    bloodline: "smart",
    completed: "FALSE",
  },
  {
    name: "Fen Lovelace",
    border: "orange",
    generation: 5,
    bloodline: "smart",
    completed: "FALSE",
  },
  {
    name: "Alya Lovelace",
    border: "orange-pink",
    generation: 1,
    bloodline: "strong",
    completed: "FALSE",
  },
  {
    name: "Vizma Lovelace",
    border: "orange-pink",
    generation: 2,
    bloodline: "strong",
    completed: "FALSE",
  },
  {
    name: "Devon Lovelace",
    border: "orange-pink",
    generation: 3,
    bloodline: "strong",
    completed: "FALSE",
  },
  {
    name: "Alys Lovelace",
    border: "orange-pink",
    generation: 4,
    bloodline: "strong",
    completed: "FALSE",
  },
  {
    name: "Agatha Lovelace",
    border: "orange-pink",
    generation: 5,
    bloodline: "strong",
    completed: "FALSE",
  },
  {
    name: "Jeananne Lovelace",
    border: "white-gold",
    generation: 1,
    bloodline: "inquisitive",
    completed: "FALSE",
  },
  {
    name: "Fannie Lovelace",
    border: "white-gold",
    generation: 2,
    bloodline: "inquisitive",
    completed: "FALSE",
  },
  {
    name: "Johanna Lovelace",
    border: "white-gold",
    generation: 3,
    bloodline: "inquisitive",
    completed: "FALSE",
  },
  {
    name: "Liselotte Lovelace",
    border: "white-gold",
    generation: 4,
    bloodline: "inquisitive",
    completed: "FALSE",
  },
  {
    name: "Mel Lovelace",
    border: "white-gold",
    generation: 5,
    bloodline: "inquisitive",
    completed: "FALSE",
  },
  {
    name: "Anika Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "innocent",
    completed: "FALSE",
  },
  {
    name: "Beau Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "innocent",
    completed: "FALSE",
  },
  {
    name: "Julia Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "innocent",
    completed: "FALSE",
  },
  {
    name: "Zaiga Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "innocent",
    completed: "FALSE",
  },
  {
    name: "Albina Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "innocent",
    completed: "FALSE",
  },
  {
    name: "Tiia Lovelace",
    border: "pink-cyan",
    generation: 1,
    bloodline: "bloody",
    completed: "FALSE",
  },
  {
    name: "Rihanna Lovelace",
    border: "pink-cyan",
    generation: 2,
    bloodline: "bloody",
    completed: "FALSE",
  },
  {
    name: "Sabine Lovelace",
    border: "pink-cyan",
    generation: 3,
    bloodline: "bloody",
    completed: "FALSE",
  },
  {
    name: "Brigita Lovelace",
    border: "pink-cyan",
    generation: 4,
    bloodline: "bloody",
    completed: "FALSE",
  },
  {
    name: "Clemence Lovelace",
    border: "pink-cyan",
    generation: 5,
    bloodline: "bloody",
    completed: "FALSE",
  },
  {
    name: "Penelope Lovelace",
    border: "purple",
    generation: 1,
    bloodline: "lively",
    completed: "FALSE",
  },
  {
    name: "Rosalie Lovelace",
    border: "purple",
    generation: 2,
    bloodline: "lively",
    completed: "FALSE",
  },
  {
    name: "Addie Lovelace",
    border: "purple",
    generation: 3,
    bloodline: "lively",
    completed: "FALSE",
  },
  {
    name: "Delena Lovelace",
    border: "purple",
    generation: 4,
    bloodline: "lively",
    completed: "FALSE",
  },
  {
    name: "Bernadeta Lovelace",
    border: "purple",
    generation: 5,
    bloodline: "lively",
    completed: "FALSE",
  },
  {
    name: "Clarinda Lovelace",
    border: "purple-green",
    generation: 1,
    bloodline: "wandering",
    completed: "FALSE",
  },
  {
    name: "Pippa Lovelace",
    border: "purple-green",
    generation: 2,
    bloodline: "wandering",
    completed: "FALSE",
  },
  {
    name: "Melly Lovelace",
    border: "purple-green",
    generation: 3,
    bloodline: "wandering",
    completed: "FALSE",
  },
  {
    name: "Bess Lovelace",
    border: "purple-green",
    generation: 4,
    bloodline: "wandering",
    completed: "FALSE",
  },
  {
    name: "Filomena Lovelace",
    border: "purple-green",
    generation: 5,
    bloodline: "wandering",
    completed: "FALSE",
  },
  {
    name: "Ligia Lovelace",
    border: "raspberry-yellow",
    generation: 1,
    bloodline: "astonished",
    completed: "FALSE",
  },
  {
    name: "Hayden Lovelace",
    border: "raspberry-yellow",
    generation: 2,
    bloodline: "astonished",
    completed: "FALSE",
  },
  {
    name: "Cherine Lovelace",
    border: "raspberry-yellow",
    generation: 3,
    bloodline: "astonished",
    completed: "FALSE",
  },
  {
    name: "Samara Lovelace",
    border: "raspberry-yellow",
    generation: 4,
    bloodline: "astonished",
    completed: "FALSE",
  },
  {
    name: "Abena Lovelace",
    border: "raspberry-yellow",
    generation: 5,
    bloodline: "astonished",
    completed: "FALSE",
  },
  {
    name: "Janica Lovelace",
    border: "red-blue",
    generation: 1,
    bloodline: "envious",
    completed: "FALSE",
  },
  {
    name: "Agne Lovelace",
    border: "red-blue",
    generation: 2,
    bloodline: "envious",
    completed: "FALSE",
  },
  {
    name: "Iveta Lovelace",
    border: "red-blue",
    generation: 3,
    bloodline: "envious",
    completed: "FALSE",
  },
  {
    name: "Ineta Lovelace",
    border: "red-blue",
    generation: 4,
    bloodline: "envious",
    completed: "FALSE",
  },
  {
    name: "Rambha Lovelace",
    border: "red-blue",
    generation: 5,
    bloodline: "envious",
    completed: "FALSE",
  },
  {
    name: "Mayo Lovelace",
    border: "red-green",
    generation: 1,
    bloodline: "bloodthirsty",
    completed: "FALSE",
  },
  {
    name: "Aloma Lovelace",
    border: "red-green",
    generation: 2,
    bloodline: "bloodthirsty",
    completed: "FALSE",
  },
  {
    name: "Christiane Lovelace",
    border: "red-green",
    generation: 3,
    bloodline: "bloodthirsty",
    completed: "FALSE",
  },
  {
    name: "Tess Lovelace",
    border: "red-green",
    generation: 4,
    bloodline: "bloodthirsty",
    completed: "FALSE",
  },
  {
    name: "Rochelle Lovelace",
    border: "red-green",
    generation: 5,
    bloodline: "bloodthirsty",
    completed: "FALSE",
  },
  {
    name: "Gerri Lovelace",
    border: "red-pink",
    generation: 1,
    bloodline: "successful",
    completed: "FALSE",
  },
  {
    name: "Mandy Lovelace",
    border: "red-pink",
    generation: 2,
    bloodline: "successful",
    completed: "FALSE",
  },
  {
    name: "Elysia Lovelace",
    border: "red-pink",
    generation: 3,
    bloodline: "successful",
    completed: "FALSE",
  },
  {
    name: "Lesli Lovelace",
    border: "red-pink",
    generation: 4,
    bloodline: "successful",
    completed: "FALSE",
  },
  {
    name: "Angela Lovelace",
    border: "red-pink",
    generation: 5,
    bloodline: "successful",
    completed: "FALSE",
  },
  {
    name: "Chrissie Lovelace",
    border: "red-yellow",
    generation: 1,
    bloodline: "focused",
    completed: "FALSE",
  },
  {
    name: "Annika Lovelace",
    border: "red-yellow",
    generation: 2,
    bloodline: "focused",
    completed: "FALSE",
  },
  {
    name: "Dannah Lovelace",
    border: "red-yellow",
    generation: 3,
    bloodline: "focused",
    completed: "FALSE",
  },
  {
    name: "Dalene Lovelace",
    border: "red-yellow",
    generation: 4,
    bloodline: "focused",
    completed: "FALSE",
  },
  {
    name: "Hermione Lovelace",
    border: "red-yellow",
    generation: 5,
    bloodline: "focused",
    completed: "FALSE",
  },
  {
    name: "Glaphyra Lovelace",
    border: "shiny-bronze",
    generation: 1,
    bloodline: "combative",
    completed: "FALSE",
  },
  {
    name: "Janice Lovelace",
    border: "shiny-bronze",
    generation: 2,
    bloodline: "combative",
    completed: "FALSE",
  },
  {
    name: "Vanita Lovelace",
    border: "shiny-bronze",
    generation: 3,
    bloodline: "combative",
    completed: "FALSE",
  },
  {
    name: "Brigid Lovelace",
    border: "shiny-bronze",
    generation: 4,
    bloodline: "combative",
    completed: "FALSE",
  },
  {
    name: "Dariga Lovelace",
    border: "shiny-bronze",
    generation: 5,
    bloodline: "combative",
    completed: "FALSE",
  },
  {
    name: "Josette Lovelace",
    border: "violet-peach",
    generation: 1,
    bloodline: "glorious",
    completed: "FALSE",
  },
  {
    name: "Susy Lovelace",
    border: "violet-peach",
    generation: 2,
    bloodline: "glorious",
    completed: "FALSE",
  },
  {
    name: "Madalena Lovelace",
    border: "violet-peach",
    generation: 3,
    bloodline: "glorious",
    completed: "FALSE",
  },
  {
    name: "Miley Lovelace",
    border: "violet-peach",
    generation: 4,
    bloodline: "glorious",
    completed: "FALSE",
  },
  {
    name: "Stina Lovelace",
    border: "violet-peach",
    generation: 5,
    bloodline: "glorious",
    completed: "FALSE",
  },
  {
    name: "Bethany Lovelace",
    border: "blue-purple",
    generation: 1,
    bloodline: "encouraging",
    completed: "FALSE",
  },
  {
    name: "Margarita Lovelace",
    border: "blue-purple",
    generation: 2,
    bloodline: "encouraging",
    completed: "FALSE",
  },
  {
    name: "Augustine Lovelace",
    border: "blue-purple",
    generation: 3,
    bloodline: "encouraging",
    completed: "FALSE",
  },
  {
    name: "Milly Lovelace",
    border: "blue-purple",
    generation: 4,
    bloodline: "encouraging",
    completed: "FALSE",
  },
  {
    name: "Adeline Lovelace",
    border: "blue-purple",
    generation: 5,
    bloodline: "encouraging",
    completed: "FALSE",
  },
  {
    name: "Marji Lovelace",
    border: "white-gold",
    generation: 1,
    bloodline: "colorful",
    completed: "FALSE",
  },
  {
    name: "Liddy Lovelace",
    border: "white-gold",
    generation: 2,
    bloodline: "colorful",
    completed: "FALSE",
  },
  {
    name: "Ugne Lovelace",
    border: "white-gold",
    generation: 3,
    bloodline: "colorful",
    completed: "FALSE",
  },
  {
    name: "Sejla Lovelace",
    border: "white-gold",
    generation: 4,
    bloodline: "colorful",
    completed: "FALSE",
  },
  {
    name: "Mena Lovelace",
    border: "white-gold",
    generation: 5,
    bloodline: "colorful",
    completed: "FALSE",
  },
  {
    name: "Mangamma Lovelace",
    border: "azure-lime",
    generation: 1,
    bloodline: "electric",
    completed: "TRUE",
  },
  {
    name: "Ginny Lovelace",
    border: "azure-lime",
    generation: 2,
    bloodline: "electric",
    completed: "TRUE",
  },
  {
    name: "Mellisa Lovelace",
    border: "azure-lime",
    generation: 3,
    bloodline: "electric",
    completed: "TRUE",
  },
  {
    name: "Udval Lovelace",
    border: "azure-lime",
    generation: 4,
    bloodline: "electric",
    completed: "TRUE",
  },
  {
    name: "Nieves Lovelace",
    border: "azure-lime",
    generation: 5,
    bloodline: "electric",
    completed: "TRUE",
  },
  {
    name: "Lauren Lovelace",
    border: "blue-cyan",
    generation: 1,
    bloodline: "static",
    completed: "FALSE",
  },
  {
    name: "Humaira Lovelace",
    border: "blue-cyan",
    generation: 2,
    bloodline: "static",
    completed: "FALSE",
  },
  {
    name: "Jarmila Lovelace",
    border: "blue-cyan",
    generation: 3,
    bloodline: "static",
    completed: "FALSE",
  },
  {
    name: "Danna Lovelace",
    border: "blue-cyan",
    generation: 4,
    bloodline: "static",
    completed: "FALSE",
  },
  {
    name: "Nella Lovelace",
    border: "blue-cyan",
    generation: 5,
    bloodline: "static",
    completed: "FALSE",
  },
  {
    name: "Kathlyn Lovelace",
    border: "blue-peach",
    generation: 1,
    bloodline: "kind",
    completed: "FALSE",
  },
  {
    name: "Nichi Lovelace",
    border: "blue-peach",
    generation: 2,
    bloodline: "kind",
    completed: "FALSE",
  },
  {
    name: "Thea Lovelace",
    border: "blue-peach",
    generation: 3,
    bloodline: "kind",
    completed: "FALSE",
  },
  {
    name: "Raewyn Lovelace",
    border: "blue-peach",
    generation: 4,
    bloodline: "kind",
    completed: "FALSE",
  },
  {
    name: "Liene Lovelace",
    border: "blue-peach",
    generation: 5,
    bloodline: "kind",
    completed: "FALSE",
  },
  {
    name: "Marilu Lovelace",
    border: "white",
    generation: 1,
    bloodline: "inconspicuous",
    completed: "FALSE",
  },
  {
    name: "Manuela Lovelace",
    border: "white",
    generation: 2,
    bloodline: "inconspicuous",
    completed: "FALSE",
  },
  {
    name: "Mirdza Lovelace",
    border: "white",
    generation: 3,
    bloodline: "inconspicuous",
    completed: "FALSE",
  },
  {
    name: "Shirley Lovelace",
    border: "white",
    generation: 4,
    bloodline: "inconspicuous",
    completed: "FALSE",
  },
  {
    name: "Kristel Lovelace",
    border: "white",
    generation: 5,
    bloodline: "inconspicuous",
    completed: "FALSE",
  },
  {
    name: "Kiera Lovelace",
    border: "blue-yellow",
    generation: 1,
    bloodline: "caring",
    completed: "FALSE",
  },
  {
    name: "Priska Lovelace",
    border: "blue-yellow",
    generation: 2,
    bloodline: "caring",
    completed: "FALSE",
  },
  {
    name: "Jovana Lovelace",
    border: "blue-yellow",
    generation: 3,
    bloodline: "caring",
    completed: "FALSE",
  },
  {
    name: "Madison Lovelace",
    border: "blue-yellow",
    generation: 4,
    bloodline: "caring",
    completed: "FALSE",
  },
  {
    name: "Edina Lovelace",
    border: "blue-yellow",
    generation: 5,
    bloodline: "caring",
    completed: "FALSE",
  },
  {
    name: "Lysette Lovelace",
    border: "blue-cyan",
    generation: 1,
    bloodline: "energetic",
    completed: "FALSE",
  },
  {
    name: "Gratia Lovelace",
    border: "blue-cyan",
    generation: 2,
    bloodline: "energetic",
    completed: "FALSE",
  },
  {
    name: "Erlea Lovelace",
    border: "blue-cyan",
    generation: 3,
    bloodline: "energetic",
    completed: "FALSE",
  },
  {
    name: "Tina Lovelace",
    border: "blue-cyan",
    generation: 4,
    bloodline: "energetic",
    completed: "FALSE",
  },
  {
    name: "Etty Lovelace",
    border: "blue-cyan",
    generation: 5,
    bloodline: "energetic",
    completed: "FALSE",
  },
  {
    name: "Birte Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "lucky",
    completed: "FALSE",
  },
  {
    name: "Pille Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "lucky",
    completed: "FALSE",
  },
  {
    name: "Shenna Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "lucky",
    completed: "FALSE",
  },
  {
    name: "Nadya Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "lucky",
    completed: "FALSE",
  },
  {
    name: "Blythe Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "lucky",
    completed: "FALSE",
  },
  {
    name: "Daneliya Lovelace",
    border: "champagne",
    generation: 1,
    bloodline: "fantastic",
    completed: "FALSE",
  },
  {
    name: "Ruzica Lovelace",
    border: "champagne",
    generation: 2,
    bloodline: "fantastic",
    completed: "FALSE",
  },
  {
    name: "Eta Lovelace",
    border: "champagne",
    generation: 3,
    bloodline: "fantastic",
    completed: "FALSE",
  },
  {
    name: "Neela Lovelace",
    border: "champagne",
    generation: 4,
    bloodline: "fantastic",
    completed: "FALSE",
  },
  {
    name: "Azahara Lovelace",
    border: "champagne",
    generation: 5,
    bloodline: "fantastic",
    completed: "FALSE",
  },
  {
    name: "Anete Lovelace",
    border: "cornflower",
    generation: 1,
    bloodline: "poised",
    completed: "FALSE",
  },
  {
    name: "Deriba Lovelace",
    border: "cornflower",
    generation: 2,
    bloodline: "poised",
    completed: "FALSE",
  },
  {
    name: "Sonia Lovelace",
    border: "cornflower",
    generation: 3,
    bloodline: "poised",
    completed: "FALSE",
  },
  {
    name: "Magda Lovelace",
    border: "cornflower",
    generation: 4,
    bloodline: "poised",
    completed: "FALSE",
  },
  {
    name: "Aimie Lovelace",
    border: "cornflower",
    generation: 5,
    bloodline: "poised",
    completed: "FALSE",
  },
  {
    name: "Tanis Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "naughty",
    completed: "FALSE",
  },
  {
    name: "Clarice Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "naughty",
    completed: "FALSE",
  },
  {
    name: "Leire Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "naughty",
    completed: "FALSE",
  },
  {
    name: "Cassidy Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "naughty",
    completed: "FALSE",
  },
  {
    name: "Darcie Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "naughty",
    completed: "FALSE",
  },
  {
    name: "Naira Lovelace",
    border: "purple",
    generation: 1,
    bloodline: "hungry",
    completed: "FALSE",
  },
  {
    name: "Rewan Lovelace",
    border: "purple",
    generation: 2,
    bloodline: "hungry",
    completed: "FALSE",
  },
  {
    name: "Candida Lovelace",
    border: "purple",
    generation: 3,
    bloodline: "hungry",
    completed: "FALSE",
  },
  {
    name: "Nasya Lovelace",
    border: "purple",
    generation: 4,
    bloodline: "hungry",
    completed: "FALSE",
  },
  {
    name: "Aiyana Lovelace",
    border: "purple",
    generation: 5,
    bloodline: "hungry",
    completed: "FALSE",
  },
  {
    name: "Twyla Lovelace",
    border: "lime",
    generation: 1,
    bloodline: "versatile",
    completed: "FALSE",
  },
  {
    name: "Becki Lovelace",
    border: "lime",
    generation: 2,
    bloodline: "versatile",
    completed: "FALSE",
  },
  {
    name: "Lidija Lovelace",
    border: "lime",
    generation: 3,
    bloodline: "versatile",
    completed: "FALSE",
  },
  {
    name: "Norma Lovelace",
    border: "lime",
    generation: 4,
    bloodline: "versatile",
    completed: "FALSE",
  },
  {
    name: "Irene Lovelace",
    border: "lime",
    generation: 5,
    bloodline: "versatile",
    completed: "FALSE",
  },
  {
    name: "Laura Lovelace",
    border: "moss",
    generation: 1,
    bloodline: "witty",
    completed: "FALSE",
  },
  {
    name: "Cady Lovelace",
    border: "moss",
    generation: 2,
    bloodline: "witty",
    completed: "FALSE",
  },
  {
    name: "Penda Lovelace",
    border: "moss",
    generation: 3,
    bloodline: "witty",
    completed: "FALSE",
  },
  {
    name: "Uxue Lovelace",
    border: "moss",
    generation: 4,
    bloodline: "witty",
    completed: "FALSE",
  },
  {
    name: "Tinashe Lovelace",
    border: "moss",
    generation: 5,
    bloodline: "witty",
    completed: "FALSE",
  },
  {
    name: "Adora Lovelace",
    border: "olive",
    generation: 1,
    bloodline: "vivid",
    completed: "FALSE",
  },
  {
    name: "Spodra Lovelace",
    border: "olive",
    generation: 2,
    bloodline: "vivid",
    completed: "FALSE",
  },
  {
    name: "Luana Lovelace",
    border: "olive",
    generation: 3,
    bloodline: "vivid",
    completed: "FALSE",
  },
  {
    name: "Nijole Lovelace",
    border: "olive",
    generation: 4,
    bloodline: "vivid",
    completed: "FALSE",
  },
  {
    name: "Neva Lovelace",
    border: "olive",
    generation: 5,
    bloodline: "vivid",
    completed: "FALSE",
  },
  {
    name: "Samia Lovelace",
    border: "orange",
    generation: 1,
    bloodline: "homeless",
    completed: "FALSE",
  },
  {
    name: "Viive Lovelace",
    border: "orange",
    generation: 2,
    bloodline: "homeless",
    completed: "FALSE",
  },
  {
    name: "Cindy Lovelace",
    border: "orange",
    generation: 3,
    bloodline: "homeless",
    completed: "FALSE",
  },
  {
    name: "Annalise Lovelace",
    border: "orange",
    generation: 4,
    bloodline: "homeless",
    completed: "FALSE",
  },
  {
    name: "Isolde Lovelace",
    border: "orange",
    generation: 5,
    bloodline: "homeless",
    completed: "FALSE",
  },
  {
    name: "Zita Lovelace",
    border: "orange-pink",
    generation: 1,
    bloodline: "eager",
    completed: "FALSE",
  },
  {
    name: "Judith Lovelace",
    border: "orange-pink",
    generation: 2,
    bloodline: "eager",
    completed: "FALSE",
  },
  {
    name: "Marguerite Lovelace",
    border: "orange-pink",
    generation: 3,
    bloodline: "eager",
    completed: "FALSE",
  },
  {
    name: "Peggy Lovelace",
    border: "orange-pink",
    generation: 4,
    bloodline: "eager",
    completed: "FALSE",
  },
  {
    name: "Bodil Lovelace",
    border: "orange-pink",
    generation: 5,
    bloodline: "eager",
    completed: "FALSE",
  },
  {
    name: "Celinda Lovelace",
    border: "blue-peach",
    generation: 1,
    bloodline: "nice",
    completed: "FALSE",
  },
  {
    name: "Regina Lovelace",
    border: "blue-peach",
    generation: 2,
    bloodline: "nice",
    completed: "FALSE",
  },
  {
    name: "Mitzi Lovelace",
    border: "blue-peach",
    generation: 3,
    bloodline: "nice",
    completed: "FALSE",
  },
  {
    name: "Guntra Lovelace",
    border: "blue-peach",
    generation: 4,
    bloodline: "nice",
    completed: "FALSE",
  },
  {
    name: "Savannah Lovelace",
    border: "blue-peach",
    generation: 5,
    bloodline: "nice",
    completed: "FALSE",
  },
  {
    name: "Signe Lovelace",
    border: "blue-purple",
    generation: 1,
    bloodline: "unusual",
    completed: "FALSE",
  },
  {
    name: "Mallory Lovelace",
    border: "blue-purple",
    generation: 2,
    bloodline: "unusual",
    completed: "FALSE",
  },
  {
    name: "Juliana Lovelace",
    border: "blue-purple",
    generation: 3,
    bloodline: "unusual",
    completed: "FALSE",
  },
  {
    name: "Nevena Lovelace",
    border: "blue-purple",
    generation: 4,
    bloodline: "unusual",
    completed: "FALSE",
  },
  {
    name: "Loredana Lovelace",
    border: "blue-purple",
    generation: 5,
    bloodline: "unusual",
    completed: "FALSE",
  },
  {
    name: "Heidrun Lovelace",
    border: "pink-cyan",
    generation: 1,
    bloodline: "amused",
    completed: "FALSE",
  },
  {
    name: "Shania Lovelace",
    border: "pink-cyan",
    generation: 2,
    bloodline: "amused",
    completed: "FALSE",
  },
  {
    name: "Carola Lovelace",
    border: "pink-cyan",
    generation: 3,
    bloodline: "amused",
    completed: "FALSE",
  },
  {
    name: "Natalee Lovelace",
    border: "pink-cyan",
    generation: 4,
    bloodline: "amused",
    completed: "FALSE",
  },
  {
    name: "Edit Lovelace",
    border: "pink-cyan",
    generation: 5,
    bloodline: "amused",
    completed: "FALSE",
  },
  {
    name: "Baiba Lovelace",
    border: "fire",
    generation: 1,
    bloodline: "cursed",
    completed: "FALSE",
  },
  {
    name: "Shawna Lovelace",
    border: "fire",
    generation: 2,
    bloodline: "cursed",
    completed: "FALSE",
  },
  {
    name: "Renita Lovelace",
    border: "fire",
    generation: 3,
    bloodline: "cursed",
    completed: "FALSE",
  },
  {
    name: "Lowiena Lovelace",
    border: "fire",
    generation: 4,
    bloodline: "cursed",
    completed: "FALSE",
  },
  {
    name: "Ariella Lovelace",
    border: "fire",
    generation: 5,
    bloodline: "cursed",
    completed: "FALSE",
  },
  {
    name: "Erminia Lovelace",
    border: "purple-green",
    generation: 1,
    bloodline: "bewitching",
    completed: "TRUE",
  },
  {
    name: "Justine Lovelace",
    border: "purple-green",
    generation: 2,
    bloodline: "bewitching",
    completed: "FALSE",
  },
  {
    name: "Rosetta Lovelace",
    border: "purple-green",
    generation: 3,
    bloodline: "bewitching",
    completed: "FALSE",
  },
  {
    name: "Trina Lovelace",
    border: "purple-green",
    generation: 4,
    bloodline: "bewitching",
    completed: "FALSE",
  },
  {
    name: "Therese Lovelace",
    border: "purple-green",
    generation: 5,
    bloodline: "bewitching",
    completed: "FALSE",
  },
  {
    name: "Maxine Lovelace",
    border: "raspberry-yellow",
    generation: 1,
    bloodline: "fishy",
    completed: "FALSE",
  },
  {
    name: "Arline Lovelace",
    border: "raspberry-yellow",
    generation: 2,
    bloodline: "fishy",
    completed: "FALSE",
  },
  {
    name: "Cyra Lovelace",
    border: "raspberry-yellow",
    generation: 3,
    bloodline: "fishy",
    completed: "FALSE",
  },
  {
    name: "Sigita Lovelace",
    border: "raspberry-yellow",
    generation: 4,
    bloodline: "fishy",
    completed: "FALSE",
  },
  {
    name: "Pipaluk Lovelace",
    border: "raspberry-yellow",
    generation: 5,
    bloodline: "fishy",
    completed: "FALSE",
  },
  {
    name: "Skyler Lovelace",
    border: "red-blue",
    generation: 1,
    bloodline: "outlawed",
    completed: "FALSE",
  },
  {
    name: "Dina Lovelace",
    border: "red-blue",
    generation: 2,
    bloodline: "outlawed",
    completed: "FALSE",
  },
  {
    name: "Vanessa Lovelace",
    border: "red-blue",
    generation: 3,
    bloodline: "outlawed",
    completed: "FALSE",
  },
  {
    name: "Mindy Lovelace",
    border: "red-blue",
    generation: 4,
    bloodline: "outlawed",
    completed: "FALSE",
  },
  {
    name: "Danah Lovelace",
    border: "red-blue",
    generation: 5,
    bloodline: "outlawed",
    completed: "FALSE",
  },
  {
    name: "Mariel Lovelace",
    border: "blue-purple",
    generation: 1,
    bloodline: "evil",
    completed: "FALSE",
  },
  {
    name: "Kirsi Lovelace",
    border: "blue-purple",
    generation: 2,
    bloodline: "evil",
    completed: "FALSE",
  },
  {
    name: "Saidat Lovelace",
    border: "blue-purple",
    generation: 3,
    bloodline: "evil",
    completed: "FALSE",
  },
  {
    name: "Sharon Lovelace",
    border: "blue-purple",
    generation: 4,
    bloodline: "evil",
    completed: "FALSE",
  },
  {
    name: "Tricia Lovelace",
    border: "blue-purple",
    generation: 5,
    bloodline: "evil",
    completed: "FALSE",
  },
  {
    name: "Ama Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "grieving",
    completed: "FALSE",
  },
  {
    name: "Rasma Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "grieving",
    completed: "FALSE",
  },
  {
    name: "Saturnina Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "grieving",
    completed: "FALSE",
  },
  {
    name: "Grozdana Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "grieving",
    completed: "FALSE",
  },
  {
    name: "Afua Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "grieving",
    completed: "FALSE",
  },
  {
    name: "Kulli Lovelace",
    border: "moss",
    generation: 1,
    bloodline: "betrayed",
    completed: "FALSE",
  },
  {
    name: "Katia Lovelace",
    border: "moss",
    generation: 2,
    bloodline: "betrayed",
    completed: "FALSE",
  },
  {
    name: "Helmi Lovelace",
    border: "moss",
    generation: 3,
    bloodline: "betrayed",
    completed: "FALSE",
  },
  {
    name: "Hazel Lovelace",
    border: "moss",
    generation: 4,
    bloodline: "betrayed",
    completed: "FALSE",
  },
  {
    name: "Tawny Lovelace",
    border: "moss",
    generation: 5,
    bloodline: "betrayed",
    completed: "FALSE",
  },
  {
    name: "Grayce Lovelace",
    border: "cerulean-citrine",
    generation: 1,
    bloodline: "different",
    completed: "FALSE",
  },
  {
    name: "Jamie Lovelace",
    border: "cerulean-citrine",
    generation: 2,
    bloodline: "different",
    completed: "FALSE",
  },
  {
    name: "Audra Lovelace",
    border: "cerulean-citrine",
    generation: 3,
    bloodline: "different",
    completed: "FALSE",
  },
  {
    name: "Janiya Lovelace",
    border: "cerulean-citrine",
    generation: 4,
    bloodline: "different",
    completed: "FALSE",
  },
  {
    name: "Marta Lovelace",
    border: "cerulean-citrine",
    generation: 5,
    bloodline: "different",
    completed: "FALSE",
  },
  {
    name: "Petra Lovelace",
    border: "olive",
    generation: 1,
    bloodline: "suspicious",
    completed: "FALSE",
  },
  {
    name: "Shara Lovelace",
    border: "olive",
    generation: 2,
    bloodline: "suspicious",
    completed: "FALSE",
  },
  {
    name: "Georgette Lovelace",
    border: "olive",
    generation: 3,
    bloodline: "suspicious",
    completed: "FALSE",
  },
  {
    name: "Mason Lovelace",
    border: "olive",
    generation: 4,
    bloodline: "suspicious",
    completed: "FALSE",
  },
  {
    name: "Hilda Lovelace",
    border: "olive",
    generation: 5,
    bloodline: "suspicious",
    completed: "FALSE",
  },
  {
    name: "Emilia Lovelace",
    border: "white",
    generation: 1,
    bloodline: "dead",
    completed: "FALSE",
  },
  {
    name: "Sarojini Lovelace",
    border: "white",
    generation: 2,
    bloodline: "dead",
    completed: "FALSE",
  },
  {
    name: "Violeta Lovelace",
    border: "white",
    generation: 3,
    bloodline: "dead",
    completed: "FALSE",
  },
  {
    name: "Tameka Lovelace",
    border: "white",
    generation: 4,
    bloodline: "dead",
    completed: "FALSE",
  },
  {
    name: "Alide Lovelace",
    border: "white",
    generation: 5,
    bloodline: "dead",
    completed: "FALSE",
  },
  {
    name: "Sinta Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "dark",
    completed: "FALSE",
  },
  {
    name: "Maya Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "dark",
    completed: "FALSE",
  },
  {
    name: "Gerrie Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "dark",
    completed: "FALSE",
  },
  {
    name: "Annalisa Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "dark",
    completed: "FALSE",
  },
  {
    name: "Merrilyn Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "dark",
    completed: "FALSE",
  },
  {
    name: "Mendy Lovelace",
    border: "azure-lime",
    generation: 1,
    bloodline: "fine",
    completed: "TRUE",
  },
  {
    name: "Frederica Lovelace",
    border: "azure-lime",
    generation: 2,
    bloodline: "fine",
    completed: "FALSE",
  },
  {
    name: "Silva Lovelace",
    border: "azure-lime",
    generation: 3,
    bloodline: "fine",
    completed: "FALSE",
  },
  {
    name: "Caris Lovelace",
    border: "azure-lime",
    generation: 4,
    bloodline: "fine",
    completed: "FALSE",
  },
  {
    name: "Hulda Lovelace",
    border: "azure-lime",
    generation: 5,
    bloodline: "fine",
    completed: "FALSE",
  },
  {
    name: "Lyubov Lovelace",
    border: "peach",
    generation: 1,
    bloodline: "easy",
    completed: "FALSE",
  },
  {
    name: "Susanne Lovelace",
    border: "peach",
    generation: 2,
    bloodline: "easy",
    completed: "FALSE",
  },
  {
    name: "Brittany Lovelace",
    border: "peach",
    generation: 3,
    bloodline: "easy",
    completed: "FALSE",
  },
  {
    name: "Christina Lovelace",
    border: "peach",
    generation: 4,
    bloodline: "easy",
    completed: "FALSE",
  },
  {
    name: "Smaida Lovelace",
    border: "peach",
    generation: 5,
    bloodline: "easy",
    completed: "FALSE",
  },
  {
    name: "Carolee Lovelace",
    border: "blue-peach",
    generation: 1,
    bloodline: "high",
    completed: "FALSE",
  },
  {
    name: "Luce Lovelace",
    border: "blue-peach",
    generation: 2,
    bloodline: "high",
    completed: "FALSE",
  },
  {
    name: "Mette Lovelace",
    border: "blue-peach",
    generation: 3,
    bloodline: "high",
    completed: "FALSE",
  },
  {
    name: "Jessalyn Lovelace",
    border: "blue-peach",
    generation: 4,
    bloodline: "high",
    completed: "FALSE",
  },
  {
    name: "Idina Lovelace",
    border: "blue-peach",
    generation: 5,
    bloodline: "high",
    completed: "FALSE",
  },
  {
    name: "Mignon Lovelace",
    border: "blue-cyan",
    generation: 1,
    bloodline: "depressed",
    completed: "FALSE",
  },
  {
    name: "Gunta Lovelace",
    border: "blue-cyan",
    generation: 2,
    bloodline: "depressed",
    completed: "FALSE",
  },
  {
    name: "Madeleine Lovelace",
    border: "blue-cyan",
    generation: 3,
    bloodline: "depressed",
    completed: "FALSE",
  },
  {
    name: "Polly Lovelace",
    border: "blue-cyan",
    generation: 4,
    bloodline: "depressed",
    completed: "FALSE",
  },
  {
    name: "Leida Lovelace",
    border: "blue-cyan",
    generation: 5,
    bloodline: "depressed",
    completed: "FALSE",
  },
  {
    name: "Peta Lovelace",
    border: "blue-yellow",
    generation: 1,
    bloodline: "deviant",
    completed: "TRUE",
  },
  {
    name: "Georgene Lovelace",
    border: "blue-yellow",
    generation: 2,
    bloodline: "deviant",
    completed: "TRUE",
  },
  {
    name: "Gudrun Lovelace",
    border: "blue-yellow",
    generation: 3,
    bloodline: "deviant",
    completed: "TRUE",
  },
  {
    name: "Inta Lovelace",
    border: "blue-yellow",
    generation: 4,
    bloodline: "deviant",
    completed: "TRUE",
  },
  {
    name: "Jenny Lovelace",
    border: "blue-yellow",
    generation: 5,
    bloodline: "deviant",
    completed: "TRUE",
  },
  {
    name: "Regiane Lovelace",
    border: "cerulean-citrine",
    generation: 1,
    bloodline: "vindictive",
    completed: "FALSE",
  },
  {
    name: "Yanet Lovelace",
    border: "cerulean-citrine",
    generation: 2,
    bloodline: "vindictive",
    completed: "FALSE",
  },
  {
    name: "Dorina Lovelace",
    border: "cerulean-citrine",
    generation: 3,
    bloodline: "vindictive",
    completed: "FALSE",
  },
  {
    name: "Anthea Lovelace",
    border: "cerulean-citrine",
    generation: 4,
    bloodline: "vindictive",
    completed: "FALSE",
  },
  {
    name: "Sherry Lovelace",
    border: "cerulean-citrine",
    generation: 5,
    bloodline: "vindictive",
    completed: "FALSE",
  },
  {
    name: "Rebecca Lovelace",
    border: "cerulean-lime",
    generation: 1,
    bloodline: "sad",
    completed: "TRUE",
  },
  {
    name: "Flann Lovelace",
    border: "cerulean-lime",
    generation: 2,
    bloodline: "sad",
    completed: "TRUE",
  },
  {
    name: "Crystal Lovelace",
    border: "cerulean-lime",
    generation: 3,
    bloodline: "sad",
    completed: "TRUE",
  },
  {
    name: "Denisse Lovelace",
    border: "cerulean-lime",
    generation: 4,
    bloodline: "sad",
    completed: "TRUE",
  },
  {
    name: "Lola Lovelace",
    border: "cerulean-lime",
    generation: 5,
    bloodline: "sad",
    completed: "TRUE",
  },
  {
    name: "Aliette Lovelace",
    border: "champagne",
    generation: 1,
    bloodline: "thoughtful",
    completed: "FALSE",
  },
  {
    name: "Cerys Lovelace",
    border: "champagne",
    generation: 2,
    bloodline: "thoughtful",
    completed: "FALSE",
  },
  {
    name: "Bobbi Lovelace",
    border: "champagne",
    generation: 3,
    bloodline: "thoughtful",
    completed: "FALSE",
  },
  {
    name: "Talia Lovelace",
    border: "champagne",
    generation: 4,
    bloodline: "thoughtful",
    completed: "FALSE",
  },
  {
    name: "Michelle Lovelace",
    border: "champagne",
    generation: 5,
    bloodline: "thoughtful",
    completed: "FALSE",
  },
  {
    name: "Ruth Lovelace",
    border: "cornflower",
    generation: 1,
    bloodline: "bored",
    completed: "FALSE",
  },
  {
    name: "Zenia Lovelace",
    border: "cornflower",
    generation: 2,
    bloodline: "bored",
    completed: "FALSE",
  },
  {
    name: "Clare Lovelace",
    border: "cornflower",
    generation: 3,
    bloodline: "bored",
    completed: "FALSE",
  },
  {
    name: "Maricel Lovelace",
    border: "cornflower",
    generation: 4,
    bloodline: "bored",
    completed: "FALSE",
  },
  {
    name: "Attia Lovelace",
    border: "cornflower",
    generation: 5,
    bloodline: "bored",
    completed: "FALSE",
  },
  {
    name: "Nona Lovelace",
    border: "dark",
    generation: 1,
    bloodline: "beautiful",
    completed: "FALSE",
  },
  {
    name: "Marjory Lovelace",
    border: "dark",
    generation: 2,
    bloodline: "beautiful",
    completed: "FALSE",
  },
  {
    name: "Meg Lovelace",
    border: "dark",
    generation: 3,
    bloodline: "beautiful",
    completed: "FALSE",
  },
  {
    name: "Eugenia Lovelace",
    border: "dark",
    generation: 4,
    bloodline: "beautiful",
    completed: "FALSE",
  },
  {
    name: "Seren Lovelace",
    border: "dark",
    generation: 5,
    bloodline: "beautiful",
    completed: "FALSE",
  },
  {
    name: "Izetta Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Saule Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Millia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Denise Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Margarethe Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Habiba Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ruza Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Arleen Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ida Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nelly Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alexandra Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Esperanza Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Siana Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nerissa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Hilma Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Danie Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Julissa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adalgisa Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jeana Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Katja Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Letty Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gabija Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Shayna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aviva Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ericka Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dayna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Molly Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Elfi Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Negin Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alaina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Evie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Roxanne Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Miranda Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jasna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bernadine Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Geraldine Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Katarina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Carlene Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Solvita Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adria Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kathy Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Logan Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kristina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nidia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Florrie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bet Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Steffi Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tillie Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ritva Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dalal Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tisha Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Siv Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Livia Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Petrina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Hedvig Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ludivine Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Shayma Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Theresia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Linor Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tiffany Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zarina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sasha Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Margie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Rosanna Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marcela Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Rae Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aggrey Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Shakuntala Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aiva Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Phoebe Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Briget Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tulsi Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nastja Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sandy Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Elza Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Iman Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nike Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Terisa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joanne Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vendela Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fatuma Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Saila Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marieke Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Greta Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gianina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Rosie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gretchen Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Clarisse Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marcie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Silvija Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Melania Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Matilde Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Johny Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Krista Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aubrey Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Hagar Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Arabella Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lelde Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joelle Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alberta Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tilly Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alevtina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Agra Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tessa Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lori Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bea Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Elissa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vlatka Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Minea Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Hortense Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Piia Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zelia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Hildr Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alondra Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maja Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zinta Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Amaya Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Agafya Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Shawnae Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Inguna Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Paige Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Persis Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maire Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Emily Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jorja Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Camelia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nahla Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adame Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sarina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Darlene Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fathia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Afreen Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vendula Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vickie Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lesia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Poppy Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lepa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adena Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zaidee Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Melissa Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Rhonda Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Isabella Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Inessa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alexia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aditi Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aslaug Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Paulette Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Babette Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fritzi Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mirabel Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Antonia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zuleika Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Leisha Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ebba Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gladys Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lila Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joy Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alanna Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sona Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kornelia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Anka Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Irma Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maresa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Letitia Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joanna Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Meara Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Teegan Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Katey Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Margherita Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Elizabeth Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Burdine Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jordan Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Salome Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Heidi Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nadja Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maud Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Valarie Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Abijah Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Esther Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lorrie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Daiga Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nabila Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sissy Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Grazyna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fanny Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Leticia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Serafina Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sara Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Althea Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kayla Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Inara Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Teri Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Haizea Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Afsana Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aleana Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Anastasia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Charlotte Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jennica Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Christa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gisele Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vicky Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nanette Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nataly Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ainslie Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tami Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gundega Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lieselotte Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Janet Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ivonne Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kim Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Karolina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fioralba Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mirella Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sieglinde Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kristi Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Benjamina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Diana Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Laurette Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Martha Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Celeste Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nancy Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sierra Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Rosamond Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ilze Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Brianna Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Yvette Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Viola Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Naomi Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sokhna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Galenka Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dorota Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Pavla Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Trude Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Malou Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Prerna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marlee Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Katerina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Pia Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Marjon Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Anthonia Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jerrie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Payton Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Debra Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bita Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Teresa Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ginevra Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mahfuza Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bettina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joey Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zulia Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fiona Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Joanie Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "April Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ermengarde Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "LaTanya Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Susannah Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lilija Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Isidora Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tea Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sienna Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dana Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dot Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Khioniya Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alima Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Josefa Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alena Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Carrie Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Adwoa Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bria Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Talisa Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Isabel Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nell Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dorrie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ellie Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ditte Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Chandrika Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Phyllis Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mary Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Cornelia Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Violetta Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Queenie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Iqra Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Margot Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Carla Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sharna Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Modra Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Belle Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Eva Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lika Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gordana Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Angel Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Anisha Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maurine Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Leonie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Indre Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Klaartje Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tammie Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Beverly Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Maila Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mimi Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ilona Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Josepha Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Daphna Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Katrina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lenina Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jada Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ingrid Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aneta Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lilly Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Velga Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sanita Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Leontina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Virginia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Loni Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Leisa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Carina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Abida Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gunhild Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Viera Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Amalie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Elle Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Friedl Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Deb Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aurora Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Linnea Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Stephanie Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kerly Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ance Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tamara Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Eula Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Eve Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Colla Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Emmeline Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bailey Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dorinda Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nivea Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Sarmite Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Austeja Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Anda Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aileen Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Macy Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dagmar Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Yaa Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Zora Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Dzidra Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Bella Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lada Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Vesta Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Astou Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lea Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Gina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Evangelina Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Tuyaa Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Shamma Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lianne Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Benedicta Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Violet Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lilia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Melita Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jacqueline Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Deidre Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ezra Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Georgina Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Alicia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Evelina Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Brooklyn Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Clarine Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ulrica Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Ashlyn Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Moonika Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Mirit Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Cristy Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Eda Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jenni Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Asa Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Basilia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Drahomira Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Candace Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aurelia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Nonna Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Lena Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Melinda Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Judie Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Michaela Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jilly Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jinny Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Cassie Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Jolene Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Cara Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Kellyn Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Binny Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Aemilia Lovelace",
    border: "silver",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Missy Lovelace",
    border: "gold",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Fleurette Lovelace",
    border: "iron",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
  {
    name: "Angelica Lovelace",
    border: "bronze",
    generation: 0,
    bloodline: "???",
    completed: "n/a",
  },
];
