import "../Content.css";
import React from "react";

const Discord = () => (
  <a href="https://discord.gg/DBQJzfdASG" target="_blank" rel="noreferrer">
    Discord
  </a>
);

export default Discord;
