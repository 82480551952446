import "./Content.css";
import { Container, Row, Col } from "reactstrap";
import SocialIcons from "./SocialIcons";
import sistersImage from "../img/sisters.png";
import bloodlinesImage from "../img/bloodlines.png";
import ogImage from "../img/og.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Container>
      <Row>
        <Col className="center-text">
          <h1 className="Content-h1">about</h1>
          <p className="Content-text Content-space-side">
            Adaz is an NFT project built on the{" "}
            <a href="https://cardano.org/" target="_blank" rel="noreferrer">
              Cardano blockchain
            </a>
            . All Adaz are based off of the{" "}
            <a
              href="https://commons.wikimedia.org/wiki/File:Ada_Lovelace_portrait.jpg"
              target="_blank"
              rel="noreferrer"
            >
              Portrait of Ada Lovelace
            </a>{" "}
            by Alfred Edward Chalon around 1838. Using an advanced machine
            learning algorithm, 900 new portraits were created. We like to view
            them as possible siblings or descendants of Ada Lovelace.
          </p>
          <img
            src={ogImage}
            alt="Original"
            className="Content-text Content-full-width-image Content-image-height-restricted"
          />
          <p className="Content-image-title">
            Original portrait and approximation by the algorithm.
          </p>
          <p className="Content-text Content-space-side">
            There are 900 unique Adaz NFTs. By completing a full set of our
            bloodline NFTs, collectors were able to mint one of a maximum of 100
            animated <Link to={"/explore/bloodlines"}>Adaz Bloodline NFTs</Link>
            . This was possible until 1 year after the project launch. 16/100
            Adaz Bloodline NFTs were created during this time. On Oct. 31. 2022
            the Adaz Bloodline NFT policy was locked and therefore no new
            bloodlines can ever be completed.
          </p>
          <img
            src={bloodlinesImage}
            alt="Bloodline"
            className="Content-text Content-full-width-image Content-image-height-restricted"
          />
          <p className="Content-image-title">
            A bloodline is a collection of 5 Adaz NFTs. There are 100 different
            bloodlines.
          </p>
          <img
            src={sistersImage}
            alt="Sisters"
            className="Content-text Content-full-width-image Content-image-height-restricted"
          />
          <p className="Content-image-title">
            Sisters of Ada Lovelance are Adaz NFTs that don't belong to a
            bloodline. Only 400 of those NFTs exist.
          </p>
          <SocialIcons></SocialIcons>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
